import { useEffect, useRef, useState } from "react";
import { MdArrowBack, MdOutlineArrowForward } from "react-icons/md";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import SEO from "../../../components/seo";
import "../style.scss";
function StoreBanners(props: any) {
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const navigate = useNavigate();
  const ref: any = useRef(null);
  const scroll = (ratio: any) => {
    ref.current.scrollLeft += ratio;
  };
  useEffect(() => {
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, []);

  function updateState() {
    const el = ref.current;
    el && setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width);
  }
  return (
    <div className="store-banner-Horizontal-Pscroll ">
      <div className="store-banner-Horizontal-title">
        <div className="store-banner-Horizontal-Heading1">Best Deals</div>
        <div
          className="d-flex justify-content-end"
          style={{ marginBottom: 10 }}
        >
          {hasScrollBar ? (
            <>
              <button
                className="store-banner-scroll-btn"
                onClick={() => {
                  scroll(-800);
                }}
              >
                <MdArrowBack />
              </button>
              <button
                className="store-banner-scroll-btn"
                onClick={() => {
                  scroll(800);
                }}
              >
                <MdOutlineArrowForward />
              </button>{" "}
            </>
          ) : null}
        </div>
      </div>
      <div className="store-banner-Horizontal-Pscroll position-relative">
        <div className="store-banner-Horizontal-PscrollBox" ref={ref}>
          {props?.data
            ? props?.data?.map((bann: any, index: number) => {
                return (
                  <div key={index} className="store-banner-Horizontal-item">
                    <div className={`store-banner-item rounded`}>
                      <SEO
                        title={props?.datas?.head}
                        description={props?.datas?.body?.slice(0, 100)}
                        image={props?.datas?.image}
                      />
                      <div className="store-banner-item-box">
                        <img
                          src={bann.img_desk}
                          className="store-banner-item-img"
                          alt="store-banner-item-img"
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}
export default StoreBanners;
