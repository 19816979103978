import React, { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import DataTable from "./dataTable";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import { notification } from "antd";

function DriverScreen() {
  const [drivers, setDrivers] = useState<any[]>([]);
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const pageSize = 12;
  const getDrivers = async () => {
    const url = API.GET_DRIVERS + `?order=DESC&page=${page}&take=${pageSize}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setDrivers(response?.data);
        setMeta(response?.meta);
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong..",
      });
    } finally {
      setLoading(false);
    }
  };
  const changePage = async (page: number, take: number) => {
    setPage(page);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    getDrivers();
  }, [page]);
  return (
    <div>
      <PageHeader title={`Drivers`}></PageHeader>
      <DataTable
        data={drivers}
        changePage={changePage}
        meta={meta}
        pageSize={pageSize}
        viewDetailsPage={() => {}}
        loading={loading}
        page={page}
      />
    </div>
  );
}

export default DriverScreen;
