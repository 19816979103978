import React, { useEffect, useState } from "react";
import API from "../../../../config/API";
import { GET } from "../../../../utils/apiCalls";
import { Avatar, Card, notification, Tag } from "antd";
import { Col, Row } from "react-bootstrap";
import { Image } from "antd";
import Meta from "antd/es/card/Meta";
import moment from "moment";
import { useSelector } from "react-redux";

function OrderDetailsPage(props: any) {
   const data = props?.data
   console.log('this is the complainant data', data)
  const [Notifications, contextHolder] = notification.useNotification();
  const Settings = useSelector((state: any) => state.Settings.Settings);

  const keys = [
    "name",
    "email",
    "phone" ,
    
  ];
  const values: any = {
    name: "Name : ",
    email: "Email : ",
    phone: "Phone : " ,
  };
  return (
    <>
      {contextHolder}
      <div>
      {Array.isArray(props?.data?.orderItems) == true
        ? props?.data?.orderItems.map((item: any) => (
            <Card bordered={false} className="mt-2">
              <Row>
                <Col md="6">
                  <Meta
                    avatar={
                      <Avatar src={item.image} size={115} shape="square" />
                    }
                    title={
                      <div className="text-capitalize">{`${item.name} `}</div> //${getVariantData(item?.variantDetails)}
                    }
                    description={
                      <div className="text-dark">
                        <div>Quantity: {item?.quantity}</div>
                        <div>BarCode: {item?.barcode}</div>
                        <div>SKU: {item?.sku}</div>

                        <div>
                          Ordered on:{" "}
                          {moment(item.createdAt).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col md="6">
                  <div>
                    Each:
                    {Number(item?.price)?.toFixed(2)} {Settings.currency}
                  </div>
                  <h6 className="text-dark fw-medium my-0 fw-bold">
                    Total:
                    {Number(item?.totalPrice)?.toFixed(2)} {Settings.currency}
                  </h6>
                </Col>
              </Row>
              {item?.variantId ? (
                <div className="mt-2">
                  {Array.isArray(item?.combination) == true ? (
                    <div>
                      {item?.combination?.map(
                        (item: any, key: number) => {
                          return (
                            <Tag bordered={false} key={key}>
                              <span>{`${item.variant}: ${item?.value} `}</span>
                            </Tag>
                          );
                        }
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </Card>
          ))
        : null}
    </div>
      <Card bordered={false}>
      <Meta
        title="Delivery Address"
        description={
          <div className="text-dark">
            <div>
              {" "}
              <Tag bordered={false} className="fw-bold" >{props?.data?.addressDetails?.type}</Tag>
            </div>
            <div className="fw-bold">{props?.data?.addressDetails?.name}</div>

            <div>City: {props?.data?.addressDetails?.city}</div>
            <div>PinCode: {props?.data?.addressDetails?.pin_code}</div>
            <div>State: {props?.data?.addressDetails?.pin_code}</div>

            <div>
              Address: {props?.data?.addressDetails?.fullAddress},
              {props?.data?.addressDetails?.geo_location}
            </div>
            <div className="fw-bold">
              Phone Number: {props?.data?.addressDetails?.code ?? ""}{" "}
              {props?.data?.addressDetails?.alt_phone}
            </div>
          </div>
        }
      />
    </Card>
    <Card bordered={false}>
      <Meta
        // title={`PaymentType: ${props?.data?.orderPayment?.paymentType ?? ""}`}
        description={
          <div className="text-dark">
            <div>
              PaymentType:{" "}
              <span className="fw-bold">
                {props?.data?.orderPayment?.paymentType ?? ""}
              </span>
            </div>
            <div>
              Total ProductPrice:{Number(props?.data?.total)?.toFixed(2)}{" "}
              {Settings.currency}
            </div>
            <div>
              Tax: {Number(props?.data?.tax)?.toFixed(2)} {Settings.currency}
            </div>
            <div>
              Discount: {Number(props?.data?.discount)?.toFixed(2)}{" "}
              {Settings.currency}
            </div>
            <div>
              Delivery Charge: {Number(props?.data?.deliveryCharge)?.toFixed(2)}{" "}
              {Settings.currency}
            </div>
            <div>
              Grand Total: {Number(props?.data?.grandTotal)?.toFixed(2)}
              {Settings.currency}
            </div>
            <hr />
            <div className="fw-bold">
              Payment status:{" "}
              <span
                className={
                  props?.data?.orderPayment?.status == "pending"
                    ? "text-secondary"
                    : props?.data?.orderPayment?.status == "failed"
                    ? "text-danger"
                    : props?.data?.orderPayment?.status == "success"
                    ? "text-success"
                    : "text-info"
                }
              >
                {props?.data?.orderPayment?.status}
              </span>
            </div>
            <div className="fw-bold">
              {props?.data?.orderPayment?.ref ? "Amount Paid" : "Total Price"}:{" "}
              {Number(props?.data?.orderPayment?.amount)?.toFixed(2)}
              {Settings.currency}
            </div>
            {props?.data?.orderPayment?.ref ? (
              <div className=" fw-bold">
                Payment Reference: {props?.data?.orderPayment?.ref}
              </div>
            ) : null}

            <div>
              orderDate:{" "}
              {moment(props?.data?.orderPayment?.createdAt).format(
                "DD/MM/YYYY"
              )}
            </div>
            <div>Order ID: {props?.data?.order_id}</div>
          </div>
        }
      />
    </Card>
    </>
  );
}

export default OrderDetailsPage;
