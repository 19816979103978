import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Select,
  Steps,
  Switch,
  notification,
} from "antd";
import Meta from "antd/es/card/Meta";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import API from "../../../config/API";
import { GET, POST, PUT } from "../../../utils/apiCalls";
import OrderStatusData from "../../../config/orderStatus.json";
import { getOrderStatus } from "../../../shared/helpers/getOrderStatus";
import { useSelector } from "react-redux";
import { Image } from "antd";
import useDidUpdateEffect from "../../../shared/hook/useDidUpdate";

const { Step } = Steps;

function OrderStatusCardAuth(props: any) {
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const User = useSelector((state: any) => state.User.user);
  const Auth = useSelector((state: any) => state.User);
  const [loading, setLoading] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState(props?.data?.status);
  const [checked, setChecked] = useState(
    props?.data?.assigned == true ? true : false
  );
  const [drivers, setDrivers] = useState<any[]>([]);
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const onFinish = async (values: any) => {
    const url = API.ORDER_STATUS_UPDATE + props?.data?.id;
    try {
      setLoading(true);
      const response: any = await PUT(url, values);
      if (response.status) {
        notificationApi.success({
          message: `Order Status updated successfully.`,
        });
        form.resetFields();
        setVisible(false);
        if (typeof props?.getOrderDetails == "function") {
          props?.getOrderDetails();
        }
      } else {
        notificationApi.error({ message: response.message ?? "" });
      }
    } catch (err) {
      notificationApi.error({ message: `Failed to update order Status` });
    } finally {
      setLoading(false);
    }

    const updatedStatus = {
      status: values.status,
      createdAt: moment().toISOString(),
      remarks: values.remark,
    };
  };
  const approveOrder = async () => {
    setLoading(true);
    const url = API.ORDER_VERIFY_PROOF + props?.data?.order_id;
    try {
      const response: any = await PUT(url, {});
      if (response?.status) {
        notificationApi.success({ message: "Order Successfully Approved" });
        setApproveModal(false);
        setVisible(false);
        form.resetFields();
        // setOrderStatus("");
        // setChecked(false);
        if (typeof props?.getOrderDetails == "function") {
          props?.getOrderDetails();
        }
      } else {
        notificationApi.error({ message: response?.message });
      }
    } catch (err) {
      notificationApi.error({ message: `Failed to update order Status` });
    } finally {
      setLoading(false);
    }
  };
  const getDrivers = async () => {
    setLoadingDrivers(true);
    const url = API.GET_DRIVERS_LIST;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setDrivers(response?.data);
      } else {
        setDrivers([]);
      }
    } catch (err) {
    } finally {
      setLoadingDrivers(false);
    }
  };
  useMemo(() => {
    setChecked(props?.data?.assigned ? true : false);
  }, []);
  useEffect(() => {
    getDrivers();
  }, []);
  const handleDownloadInvoice = async () => {
    const url = API.DOWNLOAD_INVOICE + props?.data?.id;
    try {
      setIsDownloadLoading(true);
      const response: any = await GET(url, null);
      if (response.status) {
        const pdfBuffer = response?.data?.data; // Adjusted to access the actual buffer data
        const blob = new Blob([new Uint8Array(pdfBuffer)], {
          type: "application/pdf",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `order_invoice_${moment(new Date()).format(
          "DD-MM-YYYY"
        )}.pdf`;
        document.body.appendChild(a); // Append the link to the document body
        a.click();
        document.body.removeChild(a); // Remove the link from the document
        URL.revokeObjectURL(url);
      } else {
        notificationApi["error"]({
          message: response?.message ?? "",
        });
      }
    } catch (error) {
      notificationApi["error"]({
        message: "Something went wrong.",
      });
    } finally {
      setIsDownloadLoading(false);
    }
  };
  return (
    <>
      <Card bordered={false}>
        {contextHolder}

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <span className="me-auto">
              Order Status: {getOrderStatus(props?.data?.status)}
            </span>
            {Auth.auth &&
            User?.data?.type === "seller" &&
            !["delivered", "delivery_approved"].includes(
              props?.data?.status
            ) ? (
              <Button onClick={() => setVisible(true)}>Update Status</Button>
            ) : null}
            {Auth.auth &&
            User?.data?.type === "seller" &&
            props?.data?.status == "delivered" ? (
              <Button onClick={() => setApproveModal(true)}>
                Approve Order
              </Button>
            ) : null}
          </div>
          <div className="text-dark">
            <div>
              Order Date: {moment(props?.data?.createdAt).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>

        <div className="mt-2">
          <h5>Order Status History</h5>
          {Array.isArray(props?.data?.orderStatus) == true ? (
            <Steps
              direction="vertical"
              current={props?.data?.orderStatus?.length}
            >
              {props?.data?.orderStatus?.map(
                (statusUpdate: any, index: number) => {
                  return (
                    <Step
                      key={index}
                      title={getOrderStatus(statusUpdate.status)}
                      description={
                        <>
                          <div>
                            {moment(statusUpdate.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                          <div>{statusUpdate.remark}</div>
                          {statusUpdate?.status == "packed" ? (
                            <Button
                              type="primary"
                              ghost
                              size="small"
                              onClick={handleDownloadInvoice}
                              loading={isDownloadLoading}
                              style={{
                                fontSize: 14,
                                fontWeight: "normal",
                              }}
                            >
                              Download Invoice
                            </Button>
                          ) : null}
                        </>
                      }
                    />
                  );
                }
              )}
            </Steps>
          ) : null}
        </div>
      </Card>
      <Modal
        title="Update Order Status"
        visible={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
          // setOrderStatus("");
          // setChecked(false);
        }}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={form.submit}
            loading={loading}
          >
            Update Status
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          initialValues={{
            status: props?.data?.status,
          }}
        >
          <Form.Item
            rules={[{ required: true, message: "Please Select OrderStatus" }]}
            name={"status"}
          >
            <Select
              style={{ width: 200 }}
              onChange={(val) => setOrderStatus(val)}
            >
              {OrderStatusData?.map((item: any, index: number) => {
                if (
                  ![
                    "driver_accepted",
                    "delivery_approved",
                    "delivered",
                    "out_for_delivery",
                    "shipped",
                    "pending",
                    "cancelled",
                  ].includes(item?.value)
                )
                  return (
                    <Option value={item.value} key={index}>
                      {item.title}
                    </Option>
                  );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name={"remark"}
            rules={[{ required: true, message: "Please Enter Remarks" }]}
          >
            <TextArea rows={4} placeholder="Enter remark" />
          </Form.Item>
          {orderStatus == "packed" ? (
            <Form.Item
              name="assigned"
              valuePropName="checked"
              label="Assign to Drivers"
              initialValue={props?.data?.assigned ? true : false}
            >
              <Switch
                defaultChecked={props?.data?.assigned ? true : false}
                onChange={(st) => {
                  setChecked(() => st);
                  if (st == true) {
                    getDrivers();
                  }
                }}
              />
            </Form.Item>
          ) : null}
          {loadingDrivers ? (
            <div>Loading Drivers...</div>
          ) : checked && orderStatus == "packed" ? (
            <Form.Item
              name="drivers"
              label="Select Drivers"
              rules={[{ required: true, message: "Please Select Drivers" }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Drivers"
                defaultValue={
                  Array.isArray(props?.data?.driver)
                    ? props?.data?.driver?.map((item: any) => item?.driverId)
                    : []
                }
                onChange={() => {}}
                options={drivers?.map((item: any) => ({
                  value: item?.driverId,
                  label: item?.name,
                }))}
              />
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
      <Modal
        title="Verify Proof and Approve Order"
        visible={approveModal}
        onCancel={() => setApproveModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setApproveModal(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={approveOrder}
            loading={loading}
          >
            Approve Order
          </Button>,
        ]}
      >
        <Image width={"100%"} src={props?.data?.proof} />
        <Form form={form2} name="basic" onFinish={approveOrder}>
          {/* <Form.Item
            name={"remark"}
            rules={[{ required: true, message: "Please Enter Remarks" }]}
          >
            <TextArea rows={4} placeholder="Enter remark" />
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

export default OrderStatusCardAuth;
