import React, { useEffect } from "react";
import { Modal, Form, Input, Button, notification, InputNumber } from "antd";
import { Col, Row } from "react-bootstrap";
import { POST, PUT } from "../../utils/apiCalls";
import API from "../../config/API";

const TruckFormModal = (props: any) => {
  const [form] = Form.useForm();
  const { open, modalClose, getTrucks, type, page } = props;
  const [Notifications, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (type === "update") {
      form.setFieldsValue({
        name: props?.data?.name,
        madeBy: props?.data?.madeBy,
        owner: props?.data?.owner,
        ownerId: props?.data?.ownerId,
        capacity: props?.data?.capacity,
        regNumber: props?.data?.regNumber,
        manufactureYear: props?.data?.manufactureYear,
      });
    } else {
      form.resetFields();
    }
  }, [props.data, type]);

  const formSubmitHandler = async (values: any) => {
    const url = API.TRUCKS + (type === "update" ? props?.data?.id : "");

    try {
      const response: any =
        type === "update" ? await PUT(url, values) : await POST(url, values);

      console.log(response, "Api rressdd");
      if (
        (type === "update" && response?.status) ||
        (type !== "update" && response?.id)
      ) {
        Notifications["success"]({
          message: "Success",
          description: `Successfully ${
            type === "update" ? "updated" : "added"
          } the truck`,
        });

        await getTrucks(page);

        form.resetFields();
        modalClose();
      } else {
        console.log("Error: Truck addition/update failed");
        Notifications["error"]({
          message: `Failed to ${type === "update" ? "update" : "add"}`,
          description: response?.message || "Unknown error",
        });
      }
    } catch (err: any) {
      console.error("API error:", err);

      Notifications["error"]({
        message: `Failed to ${type === "update" ? "update" : "add"}`,
        description: err.message,
      });
    }
  };

  return (
    <Modal
      title={type === "update" ? "Edit Truck" : "Add Truck"}
      open={open}
      onCancel={() => {
        modalClose();
        form.resetFields();
      }}
      footer={[
        <Button key="cancel" onClick={() => modalClose()}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {type === "update" ? "Update" : "Add"}
        </Button>,
      ]}
    >
      {contextHolder}
      <Form form={form} layout="vertical" onFinish={formSubmitHandler}>
        <Row>
          <Col md="6">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col md="6">
            <Form.Item
              label="Made By"
              name="madeBy"
              rules={[
                {
                  required: true,
                  message: "Please enter the maker",
                },
              ]}
            >
              <Input placeholder="Made By" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Item
              label="Registration Number"
              name="regNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter the registration number",
                },
              ]}
            >
              <Input placeholder="Registration Number" />
            </Form.Item>
          </Col>
          <Col md="6">
            <Form.Item
              label="Owner"
              name="owner"
              rules={[
                {
                  required: true,
                  message: "Please enter the owner",
                },
              ]}
            >
              <Input placeholder="Owner" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Form.Item
              label="Owner ID"
              name="ownerId"
              rules={[
                {
                  required: true,
                  //   type: "number",
                  message: "Please enter the valid owner ID",
                },
              ]}
            >
              <InputNumber placeholder="Owner ID" style={{width:'100%'}}/>
            </Form.Item>
          </Col>
          <Col md="6">
            <Form.Item
              label="Manufacture Year"
              name="manufactureYear"
              rules={[
                {
                  required: true,
                  //   type: "number",
                  message: "Please enter a valid manufacture year",
                },
              ]}
            >
              <InputNumber placeholder="Manufacture Year" style={{width:'100%'}}/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Item
              label="Capacity"
              name="capacity"
              rules={[
                {
                  required: true,
                  //   type: "number",
                  message: "Please enter a valid capacity",
                },
              ]}
            >
              <InputNumber placeholder="Capacity" style={{width:'100%'}}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TruckFormModal;
