import React, { useEffect, useState } from "react";
import API from "../../../../config/API";
import { GET } from "../../../../utils/apiCalls";
import { notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { Image } from "antd";

function ComplainantDetailsPage(props: any) {
   const data = props?.data
  const [Notifications, contextHolder] = notification.useNotification();

  const keys = [
    "name",
    "email",
    "phone" ,
    
  ];
  const values: any = {
    name: "Name : ",
    email: "Email : ",
    phone: "Phone : " ,
  };
  return (
    <>
      {contextHolder}
      {Object.keys(data)?.map((item: any) => {
        let displayValue
        if (keys.includes(item)) {
            if(item === "phone") {
                displayValue = `${data.countrycode} ${data.phone}`
            }
            else {
                displayValue = data[item]
            }
          return (
            <Row className="mx-0">
              <Col className="col-3">{values[item] ?? item}</Col>
              <Col className="col-9">
                {displayValue == true
                  ? "Yes"
                  : displayValue == false
                  ? "No"
                  : displayValue}
              </Col>
            </Row>
          );
        }
      })}
    </>
  );
}

export default ComplainantDetailsPage;
