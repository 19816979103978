import React, { useEffect, useState } from "react";
import API from "../../../config/API";
import { LocationType } from "../../../shared/types/types";
import { useSelector } from "react-redux";
import { GET } from "../../../utils/apiCalls";
import StoreItem from "../../../components/store_Item";
import { Row, Col } from "react-bootstrap";
export default function TopSellingStore() {
  const [topStore, setTopStore] = useState<any>();
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );
  const Settings = useSelector((state: any) => state.Settings.Settings);
  useEffect(() => {
    getTopStore();
  }, [LocationDetails.latitude, LocationDetails.longitude]);
  const getTopStore = async () => {
    const url = `${API.CORPORATE_STORE_GET_TOP_STORE}?lattitude=${LocationDetails.latitude}&longitude=${LocationDetails.longitude}&take=12&radius=${Settings?.radius}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setTopStore(response.data);
      }
    } catch (error) { }
  };

  return (
    <div className="Homescreen-TopSellingStore">
      {topStore && topStore?.length > 0 ? (
        <Row>
          <div className="Horizontal-Heading1 Homescreen-txt2">Choose your store in <span className="Homescreen-txt3">{LocationDetails?.state}</span></div>
          {topStore?.map((item: any) => (
            <Col
              key={item.id}
              xs={12}
              sm={6}
              md={4}
              
            >
              <StoreItem item={item} />
            </Col>
          ))}
        </Row>
      ) : null}
    </div>
  );
}
