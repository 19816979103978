import { Avatar, Menu, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import "../style.scss";
import { MdMenu } from "react-icons/md";
import { CiYoutube } from "react-icons/ci";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa6";
import { Row, Col } from "react-bootstrap";
export default function Header(props: any) {
  const [screenSize, getDimension] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [current, setCurrent] = useState("");
  const content = (
    <div>
      <div className="storehome-header-menu-item">Store Location</div>
      <div className="storehome-header-menu-item">Offers</div>
      <div className="storehome-header-menu-item">Contact Us</div>
      <div className="storehome-header-menu-item">Follow Us</div>
    </div>
  );
  // const onClick: MenuProps["onClick"] = (e) => {
  //   const selectedCategory = props?.categories.find(
  //     (item: any) => item._id === e.key
  //   );
  //   navigate(
  //     `categories?cid=${selectedCategory?._id}&type=${selectedCategory?.name}`
  //   );
  //   setCurrent(e.key);
  // };

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  // const items: MenuProps["items"] = (props?.categories || []).map(
  //   (item: any, index: number) => ({
  //     label: item?.name,
  //     key: item?._id,
  //   })
  // );
  return (
    <div className="storehome-header">
      <div
        className="storehome-store-details"
        onClick={() => {
          setCurrent(" ");
          navigate(`main`);
        }}
      >
        <span>
          {" "}
          <Avatar src={props?.store?.logo_upload} size={75} />
        </span>
        {screenSize <= 768 ? null : <div style={{ width: 20 }}></div>}

        <span>{props.store?.store_name}</span>
      </div>
      {screenSize <= 730 ? (
        <Popover trigger="click" content={content} arrow={false}>
          {<MdMenu size={25} />}
        </Popover>
      ) : (
        <div className="storehome-header-menu-container">
          <div className="storehome-header-menu-item">Store Location</div>
          <div className="storehome-header-menu-item">Offers</div>
          <div className="storehome-header-menu-item">Contact Us</div>
          <div className="storehome-header-menu-item">Follow Us</div>
        </div>
        // <div className="storehome-header-menu-container">
        //   {props.store?.phone ? (
        //     <div className="storehome-header-phone">
        //       {/* {screenSize <= 900 ? null : " Store No: "} */}
        //       {props.store.code} {props.store?.phone}
        //       <FaWhatsapp  size={30} className="storehome-firstsection-icon" />
        //     </div>
        //   ) : null}

        //   <div className="storehome-firstsection-icon">
        //     {<FaInstagram size={30} className="storehome-firstsection-icon" />}
        //     {/* {screenSize <= 890 ? null : "Instagram"} */}
        //   </div>
        //   <div className="storehome-firstsection-icon">
        //     {
        //       <FaFacebookSquare
        //         size={30}
        //         className="storehome-firstsection-icon"
        //       />
        //     }
        //     {/* {screenSize <= 890 ? null : "Facebook"} */}
        //   </div>
        //   <div className="storehome-firstsection-icon">
        //     {<FaTwitter size={30} className="storehome-firstsection-icon" />}
        //     {/* {screenSize <= 890 ? null : "Twitter"} */}
        //   </div>
        //   <div className="storehome-firstsection-icon">
        //     {<CiYoutube size={30} className="storehome-firstsection-icon" />}
        //     {/* {screenSize <= 890 ? null : " Youtube"} */}
        //   </div>
        //   {/* <Menu
        //   className="storehome-category-menu"
        //   overflowedIndicator={<MdMenu />}
        //   onClick={onClick}
        //   selectedKeys={[current]}
        //   mode="horizontal"
        //   items={items}
        // /> */}
        // </div>
      )}
    </div>
  );
}
