import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  Radio,
  notification,
  Space,
  SelectProps,
} from "antd";

import LocationPicker from "../component/LocationPicker";
import API from "../../../config/API";

function Step4({ formData, moveToNextStep, goBack }: any) {
  const [form] = Form.useForm();
  const [location, setLocation] = useState<any>();
  const [OpenPicker, setOpenPicker] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();

  const onFinish = async (values: any) => {
    moveToNextStep({ step4Data: values });
  };

  const handleChange = (value: string[]) => {
    console.log(value);
  };
  return (
    <div className="sellerRegister-stepbox">
      {contextHolder}
      <Container>
        <Row>
          <Col md={4}>
            <Form
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{
                account_holder_name: formData?.account_holder_name,
                account_number: formData?.account_number,
                ifsc_code: formData?.ifsc_code,
                bank_name: formData?.bank_name,
                account_type: formData?.account_type,
              }}
            >
              <div className="input-form-label">Account Holder Name</div>
              <Form.Item
                name="account_holder_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter Account Holder name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message:
                      "Account Holder name can only contain letters and spaces",
                  },
                  {
                    min: 3,
                    message:
                      "Account Holder name must be at least 3 characters long",
                  },
                  {
                    max: 50,
                    message:
                      "Account Holder name cannot be longer than 50 characters",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.trim().length < 3) {
                        return Promise.reject(
                          new Error(
                            "Account Holder name must contain at least 3 non-space characters"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Account Holder Name" size="large" />
              </Form.Item>
              <div className="input-form-label">Account Number</div>
              <Form.Item
                name="account_number"
                rules={[
                  {
                    required: true,
                    message: "Please enter your bank account number",
                  },
                  {
                    max: 18,
                    message: "Account number is too long",
                  },
                  {
                    min: 9,
                    message: "Account number is too short",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Account Number"
                  size="large"
                />
              </Form.Item>
              <div className="input-form-label">IFSC Code</div>
              <Form.Item
                name="ifsc_code"
                rules={[
                  {
                    required: true,
                    message: "Please enter IFSC code",
                  },
                  {
                    len: 11,
                    message: "IFSC code must be 11 characters long",
                  },
                  // {
                  //   pattern: /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
                  //   message: "Please enter a valid IFSC code",
                  // },
                ]}
              >
                <Input placeholder="IFSC Code" size="large" />
              </Form.Item>
              <div className="input-form-label">Bank Name</div>
              <Form.Item
                name="bank_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter Bank Name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Bank Name can only contain letters and spaces",
                  },
                  {
                    min: 3,
                    message: "Bank Name must be at least 3 characters long",
                  },
                  {
                    max: 50,
                    message: "Bank Name cannot be longer than 50 characters",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.trim().length < 3) {
                        return Promise.reject(
                          new Error(
                            "Bank Name must contain at least 3 non-space characters"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Bank Name" size="large" />
              </Form.Item>
              <div className="input-form-label">Account Type</div>
              <Form.Item name="account_type">
                <Radio.Group size="large">
                  <Radio value="savings">Savings</Radio>
                  <Radio value="current">Current</Radio>
                </Radio.Group>
              </Form.Item>
              {/* <div className="input-form-label">UPI ID</div>
              <Form.Item
                name="upi_id"
                rules={[
                  {
                    required: true,
                    message: "Please enter UPI ID",
                  },
                  {
                    pattern: /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/,
                    message: "Please enter a valid UPI ID",
                  },
                ]}
              >
                <Input placeholder="UPI ID" size="large" />
              </Form.Item> */}
              <Row>
                <Col md="6" xs={6}>
                  <Button onClick={() => goBack()} block size="large">
                    Back
                  </Button>
                </Col>
                <Col md="6" xs={6}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" block size="large">
                      Continue
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={8}>
            <div className="sellerRegister-box2">
              <h4 className="sellerRegister-subHeading">
                Add Your Bank Details
              </h4>
              <div className="sellerRegister-text1">
                To ensure you receive payments promptly and securely, please
                provide your bank account information. This will enable us to
                transfer your earnings directly to your account.
              </div>
              <br />

              <h4 className="sellerRegister-subHeading">Important Notes:</h4>
              <div className="sellerRegister-text1">
                <ul>
                  <li>
                    Ensure all the details provided are accurate and up-to-date
                    to avoid any issues with payment processing.
                  </li>
                  <li>
                    Rockana takes your security seriously and ensures all bank
                    details are encrypted and stored securely.
                  </li>
                  <li>
                    In case of any changes to your bank details, please update
                    them immediately in your seller account to prevent payment
                    interruptions.
                  </li>
                </ul>
                <br />
                For any assistance or queries regarding bank details, feel free
                to contact our support team.
                <br /> <br />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {OpenPicker ? (
        <LocationPicker
          location={location}
          visible={OpenPicker}
          onCancel={() => setOpenPicker(false)}
          onChange={(value: any) => {
            setLocation(value);
            form.setFieldValue(
              "business_location",
              `${value?.lat} ${value?.lng}`
            );
          }}
        />
      ) : null}
    </div>
  );
}

export default Step4;
