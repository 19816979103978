import React, { useEffect, useState } from "react";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import PageHeader from "../components/PageHeader";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/noData";
import AdminLoading from "../components/AdminLoading";
import UserDataTable from "./dataTable";
import { Button, notification } from "antd";
import Search from "antd/es/input/Search";
import { GrPowerReset } from "react-icons/gr";

function Users() {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const pageSize = 12;
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>({});
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [reset, setReset] = useState(false);
  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
      setReset(true);
      setSearching(true);
      await getUsersData(1, value);
      setPage(1);
      setSearching(false);
    }
  };
  const getUsersData = async (currentPage: number = 1, name: string = "") => {
    setLoading(true);
    try {
      let url =
        API.USER_CONFIG_USERS +
        `?order=DESC&page=${currentPage}&take=${pageSize}&name=${name}`;
      const response: any = await GET(url, null);
      if (response.status) {
        const { data, meta } = response;
        setUserData(data);
        setMeta(meta);
      } else {
        notificationApi.error({ message: response?.message ?? "" });
      }
    } catch (err) {
      notificationApi.error({ message: `Something went wrong` });
    } finally {
      setLoading(false);
    }
  };

  const viewDetailsPage = (record: any) => {
    navigate(`/auth/users/${record._id}`);
  };

  const changePage = async (page: number) => {
    await getUsersData(page, search);
    setPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getUsersData();
  }, []);

  return (
    <>
      <PageHeader title={`Users ${loading ? "" : `(${meta?.itemCount ?? 0})`}`}>
        <Search
          placeholder="Search Name/Phone/Email"
          allowClear
          enterButton="Search"
          size="middle"
          onSearch={onSearch}
          loading={searching}
        />
        {reset ? (
          <Button
            type="primary"
            icon={<GrPowerReset />}
            size={"middle"}
            onClick={() => {
              getUsersData();
              setReset(false);
              setPage(1);
              setSearch("");
            }}
          />
        ) : null}
      </PageHeader>
      {contextHolder}
      {loading && loading ? (
        <AdminLoading />
      ) : userData.length ? (
        <UserDataTable
          data={userData}
          changePage={changePage}
          viewDetailsPage={viewDetailsPage}
          getUsers={getUsersData}
          meta={meta}
          page={page}
          pageSize={pageSize}
        />
      ) : (
        <NoData header={"No Users Found"} />
      )}
    </>
  );
}
export default Users;
