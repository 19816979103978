import { Button, Pagination, Popconfirm, Table, notification } from "antd";
import moment from "moment";
import React, {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import API from "../../../config/API";
import { GET, PUT } from "../../../utils/apiCalls";
import AdminLoading from "../../components/AdminLoading";
import { BiSolidEditAlt } from "react-icons/bi";

type props = {
  storeId: number | string | undefined;
  closeModal: Function;
  openModal: boolean;
};
export type ResetType = {
  reset: () => void;
};
function SettlementHistory({ storeId }: props, ref: Ref<ResetType>) {
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [settlements, setSettlements] = useState<any[]>([]);
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<any>({});
  const pageSize = 12;
  const [page, setPage] = useState(1);
  const getSettlements = async (current: number = 1) => {
    const url =
      API.SETTLEMENT_DETAILS_HISTORY +
      storeId +
      `?order=ASC&page=${current}&take=${pageSize}`;
    try {
      if (storeId) {
        const response: any = await GET(url, null);
        if (response?.status) {
          setSettlements(response?.data);
          setMeta(response?.meta);
        } else {
          Notifications["error"]({
            message: response?.message ?? "",
            description: "",
          });
        }
      }
    } catch (err) {
      Notifications["error"]({
        message: "Somethig went wrong..",
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };
  const updateSettlementStatus = async (record: any) => {
    const url = API.SETTLEMENT_DETAILS_UPDATE + record?.id;
    try {
      const response: any = await PUT(url, {});
      if (response.status) {
        Notifications["success"]({
          message: "Settlement Has been Updated",
          description: "",
        });
        await getSettlements(page);
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "something went wrong.",
        description: "",
      });
    }
  };
  const changePage = async (curpage: number) => {
    await getSettlements(curpage);
    setPage(curpage);
  };
  useEffect(() => {
    getSettlements();
  }, []);

  useImperativeHandle(ref, () => ({
    reset: getSettlements,
  }));
  const columns = [
    {
      title: "Amount",
      dataIndex: "paid",
      key: "paid",
      render: (item: any) => (
        <span className="fw-medium">
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },

    {
      title: "Balance", //
      dataIndex: "balance",
      key: "balance",
      render: (item: any) => (
        <span>
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },
    {
      title: "Total Settled", //
      dataIndex: "total",
      key: "total",
      render: (item: any) => (
        <span>
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },
    {
      title: "Payment Type", //
      dataIndex: "payment_type",
      key: "payment_type",
      render: (item: any) => <span>{item}</span>,
    },
    {
      title: "Payment Status", //
      dataIndex: "status",
      key: "status",
      render: (item: any) => <span>{item}</span>,
    },
    {
      title: "Settlement Date", //
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item: any) => <span>{moment(item).format("MMM Do YYYY")}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {contextHolder}
          {_record?.status != "success" ? (
            <Popconfirm
              placement="bottomRight"
              title={"Are you sure to Complete this settlement."}
              okText="Complete"
              cancelText="No"
              onConfirm={async () => updateSettlementStatus(_record)}
            >
              <Button type="link">
                <BiSolidEditAlt cursor="pointer" size={20} color="#a10244" />
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      {loading ? (
        <AdminLoading />
      ) : (
        <>
          <Table
            size="small"
            dataSource={settlements}
            columns={columns}
            pagination={{ pageSize: 12, hideOnSinglePage: true }}
            className=""
          />
        </>
      )}

      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
    </div>
  );
}
export default forwardRef(SettlementHistory);
