import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";

import API from "../../config/API";
import { GET } from "../../utils/apiCalls";

import Loader from "./components/laoder";
import Banners from "./components/banners";
import Offers from "./components/offers";
import PopularItems from "./components/popularItems";
import Loading from "../../components/loading";
import SubCategoryList from "./components/subCategoryList";
import SEO from "../../components/seo";
import React from "react";
import TopSellingStore from "./components/TopSellingStore";
import { LocationType } from "../../shared/types/types";

function HomeSCreen() {
  const Category = useSelector((state: any) => state.Category.categries);
  const user = useSelector((state:any) => state.User.user)
  const [subCategories, setSubcategories] = useState<any[]>([]);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const Location = useSelector((state: any) => state.Location.location);
  const lattitude = Settings?.isLocation === true ? Location.latitude : "";
  const longitude = Settings?.isLocation === true ? Location.longitude : "";
  const Auth = useSelector((state: any) => state?.User?.auth);
  const [topRated, setTopRated] = useState<any[]>([]);
  const [history, setHistory] = useState<any[]>([]);
  const [crusheryProducts, setCrusheryProducts] = useState<any[]>([])
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );

  const [Banner, setBanners] = useState([]);
  const getRecommendations = async () => {
    const url =
      API.PRODUCT_RECOMMENDATIONS +
      `order=ASC&price=ASC&lat=${lattitude}&long=${longitude}&take=10&radius=${Settings?.radius}`;
    try {
      setLoading(true);
      const response: any = await GET(url, null);
      if (response.status) {
        setProducts(response.data);
        setTopRated(response?.topRated);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getCrusheryProducts = async()=>{
    try {
      const response:any = await GET(API.CRUSHERY_PRODUCT_RECOMMENDATION,null)
      if(response?.data){
        setCrusheryProducts(response?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getCrusheryProducts()
    getRecommendations();
    getBanners();
    if (Auth) {
      getUserHistory();
    }
    window.scrollTo(0, 0);
  }, [Location]);

  const getAllSubcategories = async () => {
    const subcategories: any[] = [];
    const category_subcategory: string[] = [];
    Category?.forEach((item: any) => {
      category_subcategory.push(item.name);
      item?.sub_categories?.forEach((item: any) => {
        subcategories.push(item);
        category_subcategory.push(item.name);
      });
    });
    setSubcategories(subcategories);
  };

  const getBanners = async () => {
    const url =
      API.GET_HOMESCREEN +
      `lat=${lattitude}&long=${longitude}&radius=${Settings?.radius}`;
    try {
      const banners: any = await GET(url, null);
      if (banners.status) {
        setBanners(banners.data);
      }
    } catch (err) {
      console.log("failed to get banneers");
    }
  };

  const getUserHistory = async () => {
    const url = API.USER_HISTORY;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setHistory(response?.data);
      }
    } catch (err) {}
  };

  useMemo(() => {
    getAllSubcategories();
  }, [Category]);

  return (
    <div className="Screen-box">
      <SEO
        title={API.NAME}
        description={`${API.NAME}! The leading powerhouse retailer of UAE`}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {Banner?.length ? <Banners data={Banner.slice(0, 3)} /> : null}
          <div className="HomeSCreen-space" />
          {subCategories?.length ? (
            <SubCategoryList data={subCategories} />
          ) : null}

          {LocationDetails.latitude &&
          LocationDetails.longitude &&
          Settings?.isLocation &&
          Settings?.type === "multi" ? (
            <>
              <div className="HomeSCreen-space" />
              <TopSellingStore />
            </>
          ) : (
            <>
              <div className="HomeSCreen-space" />
              <br />
              <Offers data={Banner.slice(3, 10)} />
            </>
          )}
          {loading ? (
            <Loading />
          ) : crusheryProducts?.length > 0 ? (
            <>
              <div className="HomeSCreen-space" />
              <PopularItems data={crusheryProducts} title="Crushery Items" />
            </>
          ) : null}
          {loading ? (
            <Loading />
          ) : products?.length > 0 ? (
            <>
              <div className="HomeSCreen-space" />
              <PopularItems data={products} title="Recently Arrived Products" />
            </>
          ) : null}
          {/* <div className="HomeSCreen-space" />{" "}
          <div className="HomeSCreen-space" />
          <Section1 /> */}
          <div className="HomeSCreen-space" />
          {loading ? (
            <Loading />
          ) : topRated?.length > 0 ? (
            <>
              <div className="HomeSCreen-space" />{" "}
              <PopularItems data={topRated} title="Top Rated Products" />
            </>
          ) : null}
              {/* <div className="HomeSCreen-space" />{" "}
              <Section2/>
              <div className="HomeSCreen-space" />{" "} */}

          {loading ? (
            <Loading />
          ) : history?.length > 0 && Auth ? (
            <>
              <div className="HomeSCreen-space" />{" "}
              <PopularItems data={history} title="Recently Visited Products" />
            </>
          ) : null}
        </>
      )}

      <br />
    </div>
  );
}
export default HomeSCreen;
