import { Pagination, Table } from "antd";
import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import API from "../../config/API";
import { useNavigate } from "react-router-dom";

function DataTable(props: any) {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "userDetails",
      key: "email",
      render: (userDetails: any) => userDetails?.email,
    },
    {
      title: "Phone",
      dataIndex: "userDetails",
      key: "phone",
      render: (userDetails: any) =>
        `${userDetails?.code ?? ""} ${userDetails?.phone}`,
    },
    {
      title: "View",
      key: "actions",
      width: 50,
      render: (text: any, record: any) => (
        <div
          style={{ display: "flex", justifyContent: "space-around" }}
          onClick={() => navigate(`details/${record?.driverId}`)}
        >
          <AiOutlineEye cursor="pointer" size={25} color={API.COLOR} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        size="small"
        dataSource={props.data}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {},
          };
        }}
        loading={props?.loading}
        pagination={false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
