export function getOrderStatusColor(status: string) {
  switch (status) {
    case "cancelled":
      return "orange";
    case "pending":
      return "";
    case "shipped":
      return "lime";
    case "delivered":
      return "green";
    default:
      return "";
  }
}
