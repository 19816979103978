import { Form, Input, Modal, Select, notification } from "antd";
import React, { useState } from "react";
import API from "../../../../config/API";
import { POST } from "../../../../utils/apiCalls";
type props = {
  openModal: boolean;
  driverId: number | string | undefined;
  closeModal: Function;
  refresh: Function;
};
function SettlementModal({ openModal, driverId, closeModal, refresh }: props) {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading2, setLoading2] = useState(false);
  const { Option } = Select;
  const formSubmitHandler = async (values: any) => {
    const url = API.DRIVER_TRANSACTION_SETTLEMENT;
    try {
      const body = {
        amount: Number(values?.amount),
        driverId:Number(driverId),
      };

      setLoading2(true);
      const response: any = await POST(url, body);
      if (response.status) {
        Notifications["success"]({
          message: "Settlement Amount Updated Successfully.",
          description: "",
        });
        form.resetFields();
        closeModal();
        refresh();
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
        description: "",
      });
    } finally {
      setLoading2(false);
    }
  };

  return (
    <Modal
      title="Settlement Amount"
      open={openModal}
      confirmLoading={loading2}
      onCancel={() => closeModal()}
      centered
      okText="Submit"
      onOk={() => form.submit()}
    >
      {contextHolder}

      <Form form={form} layout="vertical" onFinish={formSubmitHandler}>
        <Form.Item
          label={<>{`Enter Amount. `} </>}
          name={"amount"}
          rules={[
            {
              required: true,
              message: "Please Enter Amount",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        {/* <Form.Item
          label="Payment Status"
          name="status"
          rules={[
            {
              required: true,
              message: "Please Select Payment Status",
            },
          ]}
        >
          <Select
            bordered={false}
            style={{ width: "100%" }}
            className="border rounded"
            allowClear
          >
            <Option value={"requested"}>Requested</Option>
            <Option value={"pending"}>Pending</Option>
            <Option value={"success"}>Completed</Option>
          </Select>
        </Form.Item> */}
      </Form>
    </Modal>
  );
}

export default SettlementModal;
