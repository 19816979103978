import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import UsersTable from "../components/usersTable";
import FooterAction from "../components/footerAction";
import RolesTable from "../components/rolesTable";

const UserRoles = () => {
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    // loadUsers();
    // loadRoles();
  }, []);

  const loadUsers = async () => {
    try {
      setLoading1(false);
    } catch (err) {
      setLoading1(false);
      console.log("load Users = = = >", err);
    }
  };

  const loadRoles = async () => {
    try {
      setLoading2(false);
    } catch (err) {
      setLoading2(false);
      console.log("load Roles = = = >", err);
    }
  };

  const assignRole = async () => {
    try {
      setLoading3(false);
    } catch (err) {
      setLoading3(false);
      console.log("assign Roles = = = >", err);
    }
  };

  return (
    <div>
      <div>
        <Row>
          <Col sm={6}>
            <div className="MenuConfig-sideBox">
              <UsersTable loading={loading1} data={users} />
            </div>
          </Col>
          <Col sm={6}>
            <div className="MenuConfig-sideBox">
              <RolesTable loading={loading2} data={roles} />
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <FooterAction />
      </div>
    </div>
  );
};

export default UserRoles;
