import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import PageHeader from "../../components/pageHeader";
import React, { useEffect } from "react";
import API from "../../config/API";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt mx-2">
        <PageHeader
          text={`About ${API.NAME} – A platform to make construction easier`}
        />
        <h3 className="border-bottom border-secondary pb-3">
          Welcome to {API.NAME} - Your Construction Material Solution
        </h3>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          {API.NAME}, your ultimate destination for all construction material
          needs. Our user-friendly app is designed to provide you with easy
          access to a wide range of construction materials, ensuring your
          building projects are completed smoothly and efficiently.
        </div>
        <h5>Our Mission:</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          At {API.NAME}, our mission is to simplify the process of acquiring
          construction materials for users like you. We strive to offer a
          platform that not only provides convenience but also ensures the
          highest quality materials for your projects.
        </div>
        <h5>Why Choose {API.NAME}?</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          {API.NAME} is committed to your satisfaction. With our extensive range of
          materials, secure transactions, and user-friendly interface, we aim to
          make your experience with us seamless and rewarding.
        </div>
        <h5>Wide Selection:</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          {API.NAME} offers a diverse selection of construction materials, ensuring
          you find exactly what you need for your project, whether it's sand,
          cement, bricks, or aggregates.
        </div>
        <h5>Ease of Use:</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          Our website is designed with your convenience in mind. With intuitive
          navigation and a simple ordering process, getting the materials you
          need has never been easier.
        </div>
        <h5>Quality Assurance:</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          At {API.NAME}, we prioritize quality. We work with trusted suppliers to
          ensure that the materials you receive meet the highest standards,
          giving you peace of mind for your projects.
        </div>
        <h5>Our Promise:</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          We are dedicated to providing you with a seamless experience from
          start to finish. Whether you're a contractor, builder, or DIY
          enthusiast, {API.NAME} is here to support your construction material
          needs. Join us today and experience the {API.NAME} difference.
        </div>
      </div>
      <div className="mt-5">
        <SubscribeNewsletter />
      </div>
    </div>
  );
}
export default About;
