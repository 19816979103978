import { useEffect, useRef, useState } from "react";
import ProductItem from "../../../components/Product_Item";
import { MdArrowBack, MdOutlineArrowForward } from "react-icons/md";
import React from "react";
function RecomendedItems(props: any) {
  const [Recent, setRecent] = useState([]);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [rightButtonClicked, setRightButtonClicked] = useState(false);

  const ref: any = useRef(null);
  const scroll = (ratio:any) => {
    const currentScrollLeft = ref.current.scrollLeft;
   ref.current.scrollLeft += ratio;

    if (ratio > 0 && !rightButtonClicked) {
      setRightButtonClicked(true);
    }

    if (ratio < 0 && rightButtonClicked && ref.current.scrollLeft <= 0) {
      setRightButtonClicked(false);
    }
  };

  useEffect(() => {
    function updateState() {
      const el = ref.current;
      el &&
        setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width + 50);
    }
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [Recent]);

  function updateState() {
    const el = ref.current;
    el && setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width);
  }

  return (
    <div className="Horizontal-Pscroll">
      <div className="Horizontal-row">
        <div className="Horizontal-Heading1 mt-3">{props?.title}</div>
        <div className="Horizontal-row" style={{ marginBottom: 10 }}>
          {/* <div className="Horizontal-viewButton">See More</div> */}
        </div>
      </div>
      <div style={{ margin: 5 }} />
      <div className="Horizontal-Pscroll position-relative">
        <div className="Horizontal-PscrollBox" ref={ref}>
          {props?.data?.length
            ? props?.data?.map((prod: any) => {
                return (
                  <div key={prod.id} className="Horizontal-item">
                    <ProductItem item={prod} />
                  </div>
                );
              })
            : null}
        </div>
        {hasScrollBar ? (
          <>
          {rightButtonClicked && (
            <button
              className="Horizontal-btn1 position-absolute slider-btn-left"
              onClick={() => scroll(-800)}
            >
              <MdArrowBack />
            </button>
            )}
            <button
              className="Horizontal-btn2 slider-btn-right position-absolute"
              onClick={() => scroll(800)}
            >
              <MdOutlineArrowForward />
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}
export default RecomendedItems;
