import React, { useEffect, useState } from "react";
import API from "../../config/API";
import { useParams } from "react-router-dom";
import { GET } from "../../utils/apiCalls";
import Loading from "../../components/loading";
import NoData from "../../components/noData";
import moment from "moment";
import { message } from "antd";

function ViewInvoice() {
  const params = useParams();
  const [invoice, setInvoice] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const getInvoice = async () => {
    const url = API.INVOICE_GET_ONE + params.token;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setInvoice(response.data);
      } else {
        messageApi.error(response.message);
      }
    } catch (err) {
      messageApi.error(`Something went wrong`);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInvoice();
  }, []);
  return (
    <div className="Screen-box my-3">
      {contextHolder}
      {loading ? (
        <Loading />
      ) : invoice.invoice_id ? (
        <div
          style={{
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
            transition: "0.3s",
            maxWidth: "600px",
            backgroundColor: "#fff",
            margin: "0 auto",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <br />
            <div
              style={{ color: "#262941", fontWeight: "bold", fontSize: "20px" }}
            >
              TAX INVOICE
            </div>
          </div>

          <div style={{ padding: "20px" }}>
            <p style={{ fontSize: "16px" }}>Sold By: {API.NAME}</p>
            <p
              style={{
                fontSize: "13px",
                color: "rgb(29, 29, 29)",
                wordBreak: "break-all",
                textAlign: "start",
                margin: "0px",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Invoice Address: </span>
              {invoice.invoice_address ?? ""}
            </p>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <p
                style={{
                  border: "1px dotted black",
                  padding: "4px",
                  margin: "0px",
                  fontSize: "13px",
                }}
              >
                <span style={{ fontWeight: "bold", marginTop: "5px" }}>
                  Invoice Number:{" "}
                </span>
                {invoice.invoice_id ?? ""}
              </p>
            </div>
            <hr />
            <table
              style={{
                width: "100%",
                tableLayout: "fixed",
                padding: "10px",
              }}
            >
              <tr>
                <td>
                  <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                    Invoice Date:{" "}
                    {moment(invoice.createdAt).format("DD/MM/YYYY")}
                  </div>
                  <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                    Due Date: {moment(invoice.due_date).format("DD/MM/YYYY")}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                  >
                    <p style={{ wordBreak: "break-all" }}>
                      Bill To:{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {invoice.to_name ?? ""}{" "}
                      </span>
                      <br /> {invoice.delivery_address ?? ""}
                    </p>
                  </div>
                </td>
              </tr>
            </table>
            <br />
            <div style={{ fontSize: "13px" }}>
              Total Items:{invoice.invoiceItemDetails?.length}
            </div>

            <table
              style={{
                fontSize: "13px",
                width: "100%",
                tableLayout: "fixed",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "rgb(245, 245, 245)",
                    textAlign: "center",
                  }}
                >
                  <th>Product</th>
                  <th>Title</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Vat</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(invoice.invoiceItemDetails) == true
                  ? invoice.invoiceItemDetails?.map(
                      (item: any, key: number) => (
                        <tr style={{ textAlign: "center" }} key={key}>
                          <td>{item.product ?? ""}</td>
                          <td>{item.title ?? ""}</td>
                          <td>{item.quantity ?? ""}</td>
                          <td>{item.netPrice ?? ""}</td>
                          <td>{item.totalDiscount ?? ""}</td>
                          <td>{item.totalVat ?? ""}</td>
                          <td>{item.total ?? ""}</td>
                        </tr>
                      )
                    )
                  : null}
              </tbody>
            </table>
            <hr />
            <table
              style={{
                fontSize: "13px",
                width: "100%",
                tableLayout: "fixed",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "rgb(245, 245, 245)",
                    textAlign: "center",
                  }}
                >
                  <th colSpan={2}>Total</th>
                  <th>{invoice?.total_quantity}</th>
                  <th>{invoice?.sub_total ?? ""}</th>
                  <th>{invoice?.overall_discount ?? ""}</th>
                  <th>{invoice?.total_vat ?? ""}</th>
                  <th>{invoice?.total_amount ?? ""}</th>
                </tr>
              </thead>
            </table>
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                Grand Total:&nbsp;{invoice?.total_amount ?? ""}
              </span>
            </div>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span style={{ fontSize: "13px" }}>Authorized signatory</span>
            </div>
            <hr />
            <br />
            <br />
            <br />
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
}

export default ViewInvoice;
