import React from "react";
import { Table, Pagination, Popconfirm, notification } from "antd";
import { AiOutlineEye, AiTwotoneDelete } from "react-icons/ai";
import { BiSolidEditAlt } from "react-icons/bi";
import { DELETE } from "../../utils/apiCalls";
import API from "../../config/API";

function Trucktable(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();
  const { getTrucks, changeType, openModal, changePage, page, pageSize, meta } =
    props;

  const itemDeleteHandler = async (item: any) => {
    const url = API.TRUCKS + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response?.id) {
        Notifications["success"]({
          message: "Success",
          description: response.message,
        });
        getTrucks(page);
      } else {
        Notifications["error"]({
          message: "Failed to Delete",
          description: response.message,
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Failed to Delete",
        description: err.message,
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: 'Owner ID',
      dataIndex: 'ownerId',
      key: 'ownerId',
    },
    {
      title: "Made By",
      dataIndex: "madeBy",
      key: "madeBy",
    },
    {
      title: "Reg Number",
      dataIndex: "regNumber",
      key: "regNumber",
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (text: any, record: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BiSolidEditAlt
            cursor="pointer"
            size={20}
            color="grey"
            onClick={() => {
              changeType("update");
              openModal(record);
            }}
          />
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => itemDeleteHandler(record)}
          >
            <AiTwotoneDelete
              cursor="pointer"
              size={20}
              className="prim-color"
            />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: "View",
      key: "actions",
      width: 50,
      render: (_text: any, record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <AiOutlineEye
            cursor="pointer"
            size={25}
            className="prim-color"
            onClick={() => props?.viewDetailsPage(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <Table
        size="small"
        dataSource={props.data}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              // Handle row click if needed
            },
          };
        }}
        pagination={false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={pageSize || 10}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
    </div>
  );
}

export default Trucktable;
