import { Button, Card, notification, Popconfirm, Tag } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import ComplaintFormModal from "./complaintFormModal";
import API from "../../config/API";
import "./styles.scss"
import { PUT } from "../../utils/apiCalls";

function ComplaintCard(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();
  const complaints = props?.data?.complaints;
  const options = props?.options
  const orderId = props?.data?.order_id;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [update, setUpdate] = useState<boolean>(false);
  const editComplaint = async (id: number) => {
    try {
      const complaint = complaints.find((complaint: any) => complaint.id == id);
      if (complaint) {
        setUpdate(true);
        setData(complaint);
        setOpenModal(true);
      } else {
        showMessage(false, "Failed to update");
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const closeModal = () => {
    setData(null)
    setOpenModal(!openModal);
    setUpdate(false);
  };
  const showMessage = (status: any, message: string) => {
    if (status) {
      Notifications["success"]({
        message: "Success",
        description: message ?? "",
      });
    } else {
      Notifications["error"]({
        message: "Failed",
        description: message ?? "",
      });
    }
  };

  const updateStatus = async (id: number) => {
    try {
      const obj = {
        status: "cancelled",
      };
      const response: any = await PUT(`${API.GET_COMPLAINT_BY_ID}${id}`, obj);
      if (response?.status) {
        showMessage(response?.status, response?.message);
        props?.getOrderDetails();
      } else {
        showMessage(response?.status, response?.message);
      }
    } catch (error: any) {
      showMessage(false, error?.message);
    }
  };

  return (
    <>
      {contextHolder}
      <div style={{ float: "right" }}>
        <Button
        className="complaint-report-button"
          onClick={() => {
            setOpenModal(!openModal);
          }}
        >
          REPORT COMPLAINT
        </Button>
      </div>
      <br />
      <br />
      {complaints?.length > 0 ? (
        complaints.map((item: any) => (
          <div>
            <Card bordered={false}>
              <Meta
                title="Complaint"
                description={
                  <>
                    <div className="text-dark">
                      <div>
                        {" "}
                        <Tag bordered={false} className="fw-bold ">
                          {item?.status}
                        </Tag>
                        <br />
                        <br />
                      </div>
                      <Row style={{ paddingLeft: 15 }}>
                        <Col>
                          <Row>Against: {item?.against}</Row>
                          <Row>Email: {item?.email}</Row>
                          <Row>
                            Phone: {item?.countryCode} {item?.phone}
                          </Row>
                        </Col>
                        <Col>
                          <Row>Complaint : {item?.complaint}</Row>
                        </Col>
                      </Row>
                    </div>
                    {item?.status != 'cancelled'?<div
                      style={{ position: "absolute", bottom: 10, right: 10 }}
                    >
                      <span>
                        <a
                          className="profilescreen-address-edit-btn"
                          onClick={() => {
                            editComplaint(item?.id);
                          }}
                        >
                          Edit
                        </a>
                        <Popconfirm
                          placement="topRight"
                          title={"Are you sure to withdraw?"}
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => {
                            updateStatus(item?.id);
                          }}
                        >
                          <a className="profilescreen-address-delete-btn">
                            Withdraw
                          </a>
                        </Popconfirm>
                      </span>
                      {/* </div> */}
                    </div> : null}
                    
                  </>
                }
              />
            </Card>
            <br />
          </div>
        ))
      ) : (
        <div className="empty-complaints">
          <div className="empty-complaint-text">No complaints yet !</div>
        </div>
      )}

      {openModal ? (
        <ComplaintFormModal
          openModal={openModal}
          orderId={orderId}
          data={data}
          update={update}
          options = {options}
          closeModal={() => closeModal()}
          refreshList={() => {
            props?.getOrderDetails();
          }}
          showMessage={(status: any, message: string) => {
            showMessage(status, message);
          }}
        />
      ) : null}
    </>
  );
}

export default ComplaintCard;
