import React, { Ref, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AdminLoading from "../../../components/AdminLoading";
import { notification } from "antd";
import API from "../../../../config/API";
import { GET } from "../../../../utils/apiCalls";
type ResetType = {
  reset: () => void;
};
function SettlementSummary(props: any, ref: Ref<ResetType>) {
  const [settlements, setSettlements] = useState<any>({});
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true);

  const totalsData = [
    {
      title: "Total Amount to Settle",
      value: Number(settlements?.amountToPay)?.toFixed(2),
    },
    {
      title: "Total Amount Settled",
      value: Number(settlements?.amountReceived)?.toFixed(2),
    },
    {
      title: "Balance to Settle",
      value: Number(settlements?.balanceAmount)?.toFixed(2) ?? 0,
    },
    {
      title: "Settlement Pending",
      value: Number(settlements?.amountRequested)?.toFixed(2) ?? 0,
    },
  ];
  const getSettlementSummary = async () => {
    const url = API.GET_DRIVER_SETTLEMENT + props?.id;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setSettlements(response?.data);
      } else {
        Notifications["error"]({
          message: response?.message,
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: ``,
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSettlementSummary();
  }, []);
  useImperativeHandle(ref, () => ({
    reset: getSettlementSummary,
  }));
  return (
    <div>
      {contextHolder}
      {loading ? (
        <AdminLoading />
      ) : (
        <>
          <Row className="TotalscardContainer g-3">
            {totalsData.map((data, index) => (
              <Col md={3} key={index}>
                <div className="Totalscard">
                  <div className="title">
                    <p className="title-text">{data.title}</p>
                  </div>
                  <div className="data">
                    <p>{data.value}</p>
                    <div className="range">
                      {/* <div className="fill"></div> */}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
}

export default forwardRef(SettlementSummary);
