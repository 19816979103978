import { Button, Pagination, Popconfirm, Table, notification } from "antd";
import React, {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import AdminLoading from "../../../components/AdminLoading";
import { useSelector } from "react-redux";
import moment from "moment";
import API from "../../../../config/API";
import { GET } from "../../../../utils/apiCalls";
type ResetType = {
  reset: () => void;
};
function SettlementHistory(props: any, ref: Ref<ResetType>) {
  const [Notifications, contextHolder] = notification.useNotification();
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [settlements, setSettlements] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<any>({});
  const pageSize = 12;
  const [page, setPage] = useState(1);

  const getSettlements = async (current: number = 1) => {
    const url =
      API.GET_DRIVER_SETTLEMENT_HISTORY +
      props?.id +
      `?order=DESC&page=${current}&take=${pageSize}`;
    try {
      if (props?.id) {
        const response: any = await GET(url, null);
        if (response?.status) {
          setSettlements(response?.data);
          setMeta(response?.meta);
        } else {
          Notifications["error"]({
            message: response?.message ?? "",
            description: "",
          });
        }
      }
    } catch (err) {
      Notifications["error"]({
        message: "Somethig went wrong..",
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSettlements();
  }, []);
  useImperativeHandle(ref, () => ({
    reset: getSettlements,
  }));
  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (item: any) => (
        <span className="fw-medium">
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },

    {
      title: "Transaction", //
      dataIndex: "transaction",
      key: "transaction",
    },

    {
      title: "Status", //
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Settlement Date", //
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item: any) => <span>{moment(item).format("MMM Do YYYY")}</span>,
    },
  ];

  const changePage = async (curpage: number) => {
    await getSettlements(curpage);
    setPage(curpage);
  };
  return (
    <div>
      {contextHolder}
      {loading ? (
        <AdminLoading />
      ) : (
        <>
          <Table
            rowClassName={(record, index) => {
              if (
                record?.status == "withdrawed" &&
                record?.transaction == "debit"
              ) {
                return "settlement_row_danger";
              }else if(record?.status == "credited" &&
              record?.transaction == "credit"){
                return "settlement_row_success"
              }
              return "";
            }}
            size="small"
            dataSource={settlements}
            columns={columns}
            pagination={{ pageSize: 12, hideOnSinglePage: true }}
            className=""
          />
        </>
      )}

      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
    </div>
  );
}

export default forwardRef(SettlementHistory);
