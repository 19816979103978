import { Bar as BarChart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Colors,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "../styles.scss";
import React from "react";
import moment from "moment";

function DashBoardBarchartUsers(props: any) {
  const options = {
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stepSize: 10, // Remove this line
        min: 0,
        max: 50,
        ticks: {
          stepSize: 10, // Define the step size here
        },
      },
    },
  };
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors
  );
  const month = Array.isArray(props?.users)
    ? props?.users?.map((item: any) =>
        moment(item?.joinDate).format("MMM Do YYYY")
      )
    : [];
  const dataset = Array.isArray(props?.users)
    ? props?.users?.map((item: any) => Number(item?.userCount))
    : [];
  return (
    <>
      <div className="dashBoardBarchart-Box1">
        <div className="dashBoardBarchart-Txt1">New users on Last 7 days</div>
        <BarChart
          className="dashBoardBarchart-Box2"
          options={options}
          data={{
            labels: month,
            datasets: [
              {
                label: "users",
                data: dataset,
              },
            ],
          }}
        />
      </div>
    </>
  );
}

export default DashBoardBarchartUsers;
