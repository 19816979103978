import React, { useState } from "react";
import { Button, Col, Form, Input, Row, notification } from "antd";
import API from "../../../../config/API";
import { PUT } from "../../../../utils/apiCalls";
import { useDispatch } from "react-redux";
import { update } from "../../../../redux/slices/userSlice";

function EditNameChange(props: any) {
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  
  const updateName = async () => {
    try {
      const values = await form.validateFields();
      const { first_name, last_name } = values;
      const obj = {
        first_name,
        last_name,
      };

      const url = API.USER_NAME_UPDATE;
      setLoading(true);
      const Response: any = await PUT(url, obj);
      if (Response.status) {
        dispatch(update(Response?.data));
        notificationApi.success({ message: "Successfully Updated your Name" });
        props?.closeName()
      } else {
        notificationApi.error({ message: Response.message ?? "" });
      }
    } catch (error) {
      notificationApi.error({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="NameChange_1">
      {contextHolder}
      <Form form={form}>
        <div style={{ display: "flex" }}>
          <Row gutter={16}>
            <Col md={8} sm={8} xs={12}>
              <Form.Item name="first_name" initialValue={props?.firstname}>
                <Input size="large" placeholder="Enter Your First Name" />
              </Form.Item>
            </Col>
            <Col md={8} sm={8} xs={12}>
              <Form.Item name="last_name" initialValue={props?.lastname}>
                <Input size="large" placeholder="Enter Your Last Name" />
              </Form.Item>
            </Col>
            <Col md={8} sm={8} xs={24}>
              <Button loading={loading} onClick={updateName} size="large" type="primary">
                Update Name
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default EditNameChange;
