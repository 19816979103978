import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { GET } from "../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import NoData from "../../components/noData";
import AdminLoading from "../components/AdminLoading";
import TripTable from "./dataTable";
import TripFormModal from "./tripModal";
import API from "../../config/API";

function Trips() {
  const [tripData, setTripData] = useState<{ data: any[]; meta: any }>({ data: [], meta: {} });
  type ModalState = "add" | "update";
  const [type, setType] = useState<ModalState>("add");
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const pageSize = 10;
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = (item: any) => {
    setSelectedItem(item);
    toggleModal(true);
  };

  const toggleModal = (visible: boolean) => {
    setModalVisible(visible);
  };

  const receiveSelectedItem = (item: any) => {
    setSelectedItem(item);
  };

  const getTripData = async (currentPage: number = page) => {
    try {
      let url = API.TRIPS + `?order=DESC&page=${currentPage}&take=${pageSize}`;
      let data: any = await GET(url, null);
      console.log(data, "api Res======");
      setTripData(data);
      setMeta(data?.meta || {});
    } catch (err) {
      console.log("error occurred while fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  const viewDetailsPage = (record: any) => {
    navigate(`/auth/trips/${record.id}`);
  };

  const changePage = async (page: number) => {
    await getTripData(page);
    setPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getTripData();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <AdminLoading />;
    } else {
      return (
        <>
          {tripData?.data?.length > 0 ? (
            <TripTable
              data={tripData.data}
              changePage={changePage}
              openModal={openModal}
              getSelectedItem={receiveSelectedItem}
              changeType={() => setType("update")}
              meta={meta}
              pageSize={pageSize}
              viewDetailsPage={viewDetailsPage}
              getTrips={getTripData}
              page={page}
            />
          ) : (
            <NoData />
          )}

          <TripFormModal
            open={modalVisible}
            data={selectedItem}
            modalClose={() => toggleModal(false)}
            getTrips={getTripData}
            page={1}
            type={type}
          />
        </>
      );
    }
  };

  return (
    <>
      <PageHeader title="Trips">
        <Button
          onClick={() => {
            toggleModal(true);
            setType("add");
          }}
          type="primary"
        >
          Add Trip +
        </Button>
      </PageHeader>
      {renderContent()}
    </>
  );
}

export default Trips;
