import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, notification, InputNumber, Select } from "antd";
import { POST, PUT } from "../../utils/apiCalls";
import API from "../../config/API";
import { Col, Row } from "react-bootstrap";

interface TripFormModalProps {
  open: boolean;
  modalClose: () => void;
  getTrips: (currentPage?: number) => void;
  type: "add" | "update";
  page: number;
  data?: any;
}
const { Option } = Select;
const TripFormModal: React.FC<TripFormModalProps> = ({ open, modalClose, getTrips, type, page, data = {} }) => {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [materials, setMaterials] = useState<string[]>([]);

  useEffect(() => {
    if (type === "update") {
      form.setFieldsValue({
        title: data?.title,
        driverId: data?.driverId,
        destination: data?.destination,
        materials: data?.materials,
        weight: data?.weight,
        quantity: data?.quantity,
        deliveryInstructions: data?.deliveryInstructions,
      });
    } else {
      form.resetFields();
    }
  }, [data, type]);

  const handleMaterialChange = (value: string) => {
    // Convert the value to string and add it to the array if not already present
    const stringValue = String(value).trim();

    if (stringValue && !materials.includes(stringValue)) {
      setMaterials([...materials, stringValue]);
    }
  };

  const formSubmitHandler = async (values: any) => {
    const url = API.TRIPS + (type === "update" ? data?.id : "");
    console.log(values, "values");
    console.log(data, "dataa===");
    try {
      const response: any =
        type === "update" ? await PUT(url, values) : await POST(url, values);
      console.log(response, "apiresPUt====");
      console.log(url, "apiURL====");
      if (response.status) {
        Notifications["success"]({
          message: "Success",
          description: `Successfully ${
            type === "update" ? "updated" : "added"
          } the trip`,
        });

        await getTrips(page);

        form.resetFields();
        modalClose();
      } else {
        Notifications["error"]({
          message: `Failed to ${type === "update" ? "update" : "add"}`,
          description: response?.message || "Unknown error",
        });
      }
    } catch (err: any) {
      console.error("API error:", err);

      Notifications["error"]({
        message: `Failed to ${type === "update" ? "update" : "add"}`,
        description: err.message,
      });
    }
  };

  return (
    <Modal
      title={type === "update" ? "Edit Trip" : "Add Trip"}
      open={open}
      onCancel={() => {
        modalClose();
        form.resetFields();
      }}
      footer={[
        <Button key="cancel" onClick={() => modalClose()}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {type === "update" ? "Update" : "Add"}
        </Button>,
      ]}
    >
      {contextHolder}
      <Form form={form} layout="vertical" onFinish={formSubmitHandler}>
      <Row>
        <Col md="6">
          <Form.Item
            label="Origin"
            name="title"
            rules={[
              {
                required: true,
                message: "Please enter the origin location",
              },
            ]}
          >
            <Input placeholder="Origin" />
          </Form.Item>
        </Col>
        <Col md="6">
          <Form.Item
            label="Destination"
            name="destination"
            rules={[
              {
                required: true,
                message: "Please enter the destination",
              },
            ]}
          >
            <Input placeholder="Destination" />
          </Form.Item>
        </Col>
      </Row>
        {/* <Form.Item
          label="Driver ID"
          name="driverId"
          rules={[
            {
              required: true,
              message: "Please enter the driver ID",
            },
          ]}
        >
          <InputNumber placeholder="Driver ID" style={{ width: "100%" }} />
        </Form.Item> */}
        <Row>
          <Form.Item label="Materials" name="materials">
            <Select
              mode="tags"
              placeholder="Select or enter materials"
              onChange={handleMaterialChange}
              tokenSeparators={[","]}
            >
              {materials.map((material: any) => (
                <Option key={material} value={material}>
                  {material}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
        <Row>
          <Col md="6">
            <Form.Item
              label="Weight"
              name="weight"
              rules={[
                {
                  required: true,
                  message: "Please enter the weight",
                },
              ]}
            >
              <InputNumber
                placeholder="Quantity" style={{ width: "100%" }} 
                formatter={(value) => (value ? String(value).replace(/[^0-9]/g, '') : '')}
                parser={(value) => parseInt(value || '0', 10)}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    return;
                  }
                  if (!/^[0-9]$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col md="6">
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "Please enter the quantity",
                },
              ]}
            >
              <InputNumber
                placeholder="Quantity" style={{ width: "100%" }} 
                formatter={(value) => (value ? String(value).replace(/[^0-9]/g, '') : '')}
                parser={(value) => parseInt(value || '0', 10)}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    return;
                  }
                  if (!/^[0-9]$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item
            label="Delivery Instructions"
            name="deliveryInstructions"
            rules={[
              {
                required: true,
                message: "Please enter the delivery instructions",
              },
            ]}
          >
            <Input.TextArea placeholder="Delivery Instructions" />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default TripFormModal;
