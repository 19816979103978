import { Button, Pagination, Popconfirm, Table, notification } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
function SettlementDataTable(props: any) {
  const navigate = useNavigate();
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const columns = [
    {
      title: "Amount",
      dataIndex: "paid",
      key: "paid",
      render: (item: any) => (
        <span className="fw-medium">
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },

    {
      title: "Balance", //
      dataIndex: "balance",
      key: "balance",
      render: (item: any) => (
        <span>
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },
    {
      title: "Total Settled", //
      dataIndex: "total",
      key: "total",
      render: (item: any) => (
        <span>
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },
    {
      title: "Payment Type", //
      dataIndex: "payment_type",
      key: "payment_type",
      render: (item: any) => <span>{item}</span>,
    },
    {
      title: "Payment Status", //
      dataIndex: "status",
      key: "status",
      render: (item: any) => <span>{item}</span>,
    },
    {
      title: "Settlement Date", //
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item: any) => <span>{moment(item).format("MMM Do YYYY")}</span>,
    },
    
  ];
  return (
    <div>
      <Table
        size="small"
        dataSource={props?.data?.length ? props.data : []}
        columns={columns}
        pagination={false}
        loading={props?.loading}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current);
          }}
        />
      </div>
    </div>
  );
}

export default SettlementDataTable;
