import React, { useEffect, useState } from "react";
import NoData from "../../../components/noData";
import Loading from "../../../components/loading";
import AddressModal from "../modal/addressModal";
import useToggle from "../../../shared/hook/useToggle";
import { Button, notification } from "antd";
import API from "../../../config/API";
import { useSelector } from "react-redux";
import { DELETE, GET } from "../../../utils/apiCalls";
import { BsFillBookmarkFill } from "react-icons/bs";
import AddressDataList from "../components/AddressDataList";
import "../styles.scss";
const ProfileAddress = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<any>([]);
  const [openModal, toggleModal] = useToggle(false);
  const [type, setType] = useState<string>("update");
  const User = useSelector((state: any) => state.User.user);
  const [selected, setSelected] = useState<any>({});
  const [notificationApi, contextHolder] = notification.useNotification();
  const pageSize = 5;
  useEffect(() => {
    fetchAddresses();
  }, []);
  const fetchAddresses = async () => {
    const url = API.ADDRESS_GET;
    setIsLoading(true);
    try {
      const address: any = await GET(url, null);
      if (address?.status) {
        setAddress(address?.data);
      } else {
        notificationApi.error({ message: address.message ?? "" });
      }
    } catch (err) {
      notificationApi.error({ message: `something went wrong!` });
    } finally {
      setIsLoading(false);
    }
  };
  
  const getSelectedItem = (item: any) => {
    setSelected(item);
  };
  const deleteAddress = async (item: any) => {
    const url = API.ADDRESS + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response?.status) {
        notificationApi.success({ message: `Address removed successfully.` });
        fetchAddresses();
      } else {
        notificationApi.error({ message: response?.message });
      }
    } catch (err) {
      notificationApi.error({ message: `something went wrong!` });
    }
  };

  return (
    <>
      <div className="mb-3">
        {contextHolder}
        <div>
          <Button
            className="profilescreen-address-add-btn"
            onClick={() => {
              toggleModal(true);
              setType("add");
            }}
            type="dashed"
          >
            + Add Address
          </Button>
        </div>
        {isLoading ? (
          <Loading />
        ) : address.length ? (
          <AddressDataList
            toggleModal={toggleModal}
            setType={() => setType("update")}
            data={address}
            getSelected={getSelectedItem}
            delete={deleteAddress}
            pageSize={pageSize}
          />
        ) : (
          <NoData
            icon={<BsFillBookmarkFill size={70} color="#e6e6e6" />}
            header="No Address Found"
            text1={`You have not added any Address yet!`}
          />
        )}

        <AddressModal
          open={openModal}
          modalClose={() => toggleModal(false)}
          type={type}
          selected={selected}
          fetchAddress={fetchAddresses}
        />
      </div>
    </>
  );
};

export default ProfileAddress;
