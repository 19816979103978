import React from "react";
import { BiSolidEditAlt } from "react-icons/bi";
import API from "../../config/API";
import { Button, notification, Pagination, Popconfirm, Table } from "antd";
import { AiTwotoneDelete } from "react-icons/ai";
import { DELETE } from "../../utils/apiCalls";

function DataTable(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();
  const itemDeleteHandler = async (item: any) => {
    const url = API.UNIT + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response.status) {
        Notifications["success"]({
          message: "Success",
          description: response.message,
        });
        props?.getUnit();
      } else {
        Notifications["error"]({
          message: "Failed to Delete",
          description: response.message??'',
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Failed to Delete",
        description: err.message,
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <BiSolidEditAlt
            cursor="pointer"
            size={25}
            color={API.COLOR}
            onClick={() => {
              props?.openModal();
              props?.changeType();
              props?.receiveData(_record);
            }}
          />

          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => itemDeleteHandler(_record)}
          >
            <Button type="link">
              <AiTwotoneDelete cursor="pointer" size={25} color={API.COLOR} />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      {contextHolder}
      <Table
        size="small"
        dataSource={props.data}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {},
          };
        }}
        pagination={false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 10}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current, size);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable