import React from 'react';
import { Table, Pagination, Popconfirm, notification } from 'antd';
import { AiOutlineEye, AiTwotoneDelete } from 'react-icons/ai';
import { BiSolidEditAlt } from 'react-icons/bi';
import { DELETE } from '../../utils/apiCalls';
import API from '../../config/API';

interface TripTableProps {
  data: any[];
  changePage: (page: number) => void;
  openModal: (item: any) => void;
  getSelectedItem: (item: any) => void;
  meta: any;
  changeType: any;
  pageSize: number;
  viewDetailsPage: (record: any) => void;
  getTrips: (currentPage?: number) => void;
  page: number;
}

const TripTable: React.FC<TripTableProps> = ({
  data,
  changePage,
  openModal,
  getSelectedItem,
  changeType,
  meta,
  pageSize,
  viewDetailsPage,
  getTrips,
  page,
}) => {
  const [Notifications, contextHolder] = notification.useNotification();

  const itemDeleteHandler = async (item: any) => {
    const url = API.TRIPS + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response.status) {
        Notifications['success']({
          message: 'Success',
          description: response.message,
        });
        getTrips(page);
      } else {
        Notifications['error']({
          message: 'Failed to Delete',
          description: response.message,
        });
      }
    } catch (err: any) {
      Notifications['error']({
        message: 'Failed to Delete',
        description: err.message,
      });
    }
  };

  const columns = [
    {
      title: 'Origin',
      dataIndex: 'title',
      key: 'title',
    },
    // {
    //   title: 'Driver ID',
    //   dataIndex: 'driverId',
    //   key: 'driverId',
    // },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 80,
      render: (text: any, record: any) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <BiSolidEditAlt
            cursor="pointer"
            size={20}
            color="grey"
            onClick={() => {
              getSelectedItem(record);
              changeType("update");
              openModal(record);
            }}
          />
          <Popconfirm
            placement="bottomRight"
            title={'Are you sure to delete?'}
            okText="Yes"
            cancelText="No"
            onConfirm={() => itemDeleteHandler(record)}
          >
            <AiTwotoneDelete cursor="pointer" size={20} className="prim-color" />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: 'View',
      key: 'view',
      width: 50,
      render: (_text: any, record: any) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <AiOutlineEye
            cursor="pointer"
            size={25}
            className="prim-color"
            onClick={() => viewDetailsPage(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <Table
        size="small"
        dataSource={data}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              // Handle row click if needed
            },
          };
        }}
        pagination={false}
      />
      <div className="d-flex justify-content-center my-3">
        <Pagination
          current={page || 1}
          pageSize={pageSize || 10}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
    </div>
  );
};

export default TripTable;
