import React from "react";
import "../styles.scss";
import { useSelector } from "react-redux";
import menu from "./menu.json";
import SideItem from "./sidebarItem";
import { useParams } from "react-router-dom";

function SideBar() {
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const Store = useSelector((state: any) => state.Store.Store);
  const User = useSelector((state: any) => state.User.user);
  const params = useParams();
  const path = params["*"];
  return (
    <div className="admimSidebarBox">
      <div className="admimSidebar">
        <div className="admimSidebar-storeBox">
          <div>
            <img
              src={
                User?.data?.role == "admin"
                  ? User?.data?.image
                  : Store?.logo_upload ?? ""
              }
              className="admimSidebar-logo"
              alt=""
            />
          </div>
          <div style={{ marginLeft: 10 }}>
            <div className="admimSidebar-txt1">{Store?.store_name}</div>
            <div className="admimSidebar-txt2">{User?.data?.role}</div>
          </div>
        </div>
        {menu.map((item) => {
          return (
            <div key={item.id}>
              {Settings?.type === "multi" ? (
                item?.role?.includes(User?.data?.role) ? (
                  <SideItem item={item} path={path} />
                ) : null
              ) : item?.type?.includes("both") ? (
                <SideItem item={item} path={path} />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default SideBar;
