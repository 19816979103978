import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../components/PageHeader";
import {
  Button,
  Form,
  Modal,
  notification,
  Select,
  Tabs,
  TabsProps,
} from "antd";

// import SettlementSummary from "./tabs/settlementSummary";
// import SettlementHistory from "./tabs/settlementHistory";
import { useParams } from "react-router-dom";
import useToggle from "../../../shared/hook/useToggle";
import { GET, PUT } from "../../../utils/apiCalls";
import API from "../../../config/API";
import ComplainantDetailsPage from "./tabs/complainantDetails";
import ComplaintDetailsPage from "./tabs/complaintDetails";
import RespondendDetailsPage from "./tabs/respondendDetails";
import OrderDetailsPage from "./tabs/orderDetails";
import ComplaintStatusData from "../../../config/complaintStatus.json";

export type ResetType = {
  reset: () => void;
};
function ComplaintDetailsScreen() {
  const [Notifications, contextHolder] = notification.useNotification();
  const { Option } = Select;
  const [form] = Form.useForm();
  const params = useParams();
  const [orderData, setOrderData] = useState<any>([]);
  const [orderStatus, setOrderStatus] = useState(orderData?.status);
  const resetRef = useRef<ResetType>(null);
  const resetRef2 = useRef<ResetType>(null);
  const [modal, toggleModal] = useToggle(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const items: TabsProps["items"] = [
    {
      key: "info",
      label: "Complaint Info",
      children: <ComplaintDetailsPage data={orderData} />,
    },
    {
      key: "complainant",
      label: "Complainant Details",
      children: <ComplainantDetailsPage data={orderData?.fromUser} />,
    },
    {
      key: "respondend",
      label: "Respondend Details",
      children: <RespondendDetailsPage data={orderData?.againstUser} />,
    },
    {
      key: "order",
      label: "Order Details",
      children: <OrderDetailsPage data={orderData?.orderDetails} />,
    },
  ];

  useEffect(() => {
    getComplaintDetails();
  }, []);

  const getComplaintDetails = async () => {
    try {
      const response: any = await GET(
        `${API.GET_COMPLAINT_BY_ID}${params?.id}`,
        null
      );
      if (response?.status) {
        setOrderData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (val: any) => {
    try {
      const obj = {
        status: val?.status,
      };
      const response: any = await PUT(
        `${API.GET_COMPLAINT_BY_ID}${params?.id}`,
        obj
      );
      if (response?.status) {
        Notifications["success"]({
          message: "Success",
          description: response?.message,
        });
        setVisible(false)
        getComplaintDetails()
      } else {
        Notifications["error"]({
          message: "Failed",
          description: response?.message,
        });
      }
    } catch (error) {
        console.log(error)
      Notifications["error"]({
        message: "Something went wrong.",
      });
    }
  };
  return (
    <>
    {contextHolder}
      <PageHeader title="Complaint Details">
        <Button
          type="primary"
          onClick={() => {
            setVisible(!visible);
          }}
        >
          Update Status
        </Button>
      </PageHeader>
      <Tabs defaultActiveKey={"info"} items={items} onChange={() => {}} />
      <Modal
        title="Update Order Status"
        visible={visible}
        onCancel={() => {
          setVisible(false);
          //   form.resetFields();
          // setOrderStatus("");
          // setChecked(false);
        }}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={form.submit}
            loading={loading}
          >
            Update Status
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          initialValues={{
            status: orderData?.status,
          }}
        >
          <Form.Item
            rules={[{ required: true, message: "Please Select OrderStatus" }]}
            name={"status"}
          >
            <Select
              style={{ width: 200 }}
              onChange={(val) => setOrderStatus(val)}
            >
              {ComplaintStatusData?.map((item: any, index: number) => {
                if (!["pending", "cancelled"].includes(item?.value))
                  return (
                    <Option value={item.value} key={index}>
                      {item.title}
                    </Option>
                  );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ComplaintDetailsScreen;
