import { Outlet, Navigate } from "react-router-dom";
import React from "react";
function ProtectedRouteAdmin({ isSignedIn, role }: any) {
  if (isSignedIn === true && (role === "admin" || role === "seller")) {
    return <Outlet />;
  } else {
    return <Navigate to="/" replace />;
  }
}
export default ProtectedRouteAdmin;
