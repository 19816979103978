import { Table, Button } from "antd";
import React from "react";

const UsersTable = (props: any) => {
  const roleColumns = [
    {
      title: "",
      dataIndex: "",
      key: "",
    },
    {
      title: <span>Name</span>,
      dataIndex: "name",
      key: "id",
      width: "70%",
    },
    {
      title: <span>Assigned Role</span>,
      dataIndex: "id",
      key: "id",
    },
  ];

  return (
    <div>
      <div className="MenuConfig-rowBox">
        <div className="MenuConfig-txt1">All Users</div>
        <div style={{ flex: 1 }} />
        <div>
          <Button size="small">refesh</Button>
        </div>
      </div>
      <Table
        size="small"
        bordered
        dataSource={[]}
        columns={roleColumns}
        rowKey={(data: any) => data?.id}
        pagination={false}
        loading={props?.loading}
      />
    </div>
  );
};

export default UsersTable;
