import {
  useEffect,
  useState,
  forwardRef,
  Ref,
  useImperativeHandle,
} from "react";
import { Button, Form, Input, Radio, Select } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextArea from "antd/es/input/TextArea";
import React from "react";
export type ResetType = {
  reset: () => void;
};

function Information(props: any, ref: Ref<ResetType>) {
  const unit = props?.unit;
  const [subCategories, setSubCategories] = useState<any>([]);
  const [specifications, setSpecifications] = useState(
    props?.data?.specifications
  );
  const [form] = Form.useForm();
  const categoryChangeHandler = (value: any) => {
    const index: any = props?.categories?.findIndex(
      (item: any) => item.id == value
    );
    if (index != -1) {
      let item = props?.categories[index]?.sub_categories;
      setSubCategories(item);
      form.setFieldsValue({ subCategory: "" });
    }
  };

  useEffect(() => {
    categoryChangeHandler(props?.data?.category);
  }, [props?.data?.category]);
  const submit = async (values: any) => {
    try {
      let obj = values;
      obj["specifications"] = specifications;
      props.onChange(obj);
    } catch (err) {
      console.log("err", err);
    }
  };
  const reset = () => {
    form.resetFields();
  };
  useImperativeHandle(ref, () => ({
    reset,
  }));
  return (
    <Container>
      <Form
        onFinish={submit}
        initialValues={{
          bar_code: props?.data?.bar_code,
          sku: props?.data?.sku,
          brand: props?.data?.brand,
          bulk_order: props?.data?.bulk_order,
          category: props?.data?.category,
          manufacture: props?.data?.manufacture,
          purchase_rate: props?.data?.purchase_rate,
          retail_rate: props?.data?.retail_rate,
          status: props?.data?.status,
          subCategory: props?.data?.subCategory,
          name: props?.data?.name,
          description: props?.data?.description,
          unit: props?.data?.unit,
          units: props?.data?.units,
          unitId: props?.data?.unitId,
          crushery: props?.data?.crushery,
        }}
        scrollToFirstError
        form={form}
      >
        <Row>
          <Col sm={6}>
            <div className="input-form-label">Category *</div>
            <Form.Item
              name={"category"}
              rules={[{ required: true, message: "Please chose Category" }]}
            >
              <Select
                style={{ width: "100%" }}
                className="border rounded"
                allowClear
                onChange={categoryChangeHandler}
                placeholder="Select category"
              >
                {props?.categories?.map((item: any, index: number) => (
                  <Select.Option key={index} value={Number(item.id)}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="input-form-label">Sub Category *</div>
            <Form.Item
              name={"subCategory"}
              rules={[{ required: true, message: "Please chose Subcategory" }]}
            >
              <Select
                style={{ width: "100%" }}
                className="border rounded"
                allowClear
                placeholder="Select  Subcategory"
              >
                {subCategories?.map((item: any, index: number) => (
                  <Select.Option key={index} value={Number(item._id)}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="input-form-label">Product Title *</div>
            <Form.Item
              name={"name"}
              rules={[
                { required: true, message: "Please Enter Product Name" },
                { max: 200, message: "Product name length is too much" },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
            <div className="input-form-label">Is this a Crushery Item ?</div>
            <Form.Item
              name="crushery"
              rules={[
                { required: true, message: "Please Select if this is a crushery item or not" },
              ]}
            >
              <Radio.Group size="large">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <div className="input-form-label">Description *</div>
            <Form.Item
              name={"description"}
              rules={[
                { required: true, message: "Please Enter product Description" },
                { max: 250, message: "Product Description length is too much" },
              ]}
            >
              <TextArea rows={4} placeholder="Description" maxLength={250} />
            </Form.Item>
          </Col>
          <Col sm={6}>
            <div className="input-form-label">Brand</div>
            <Form.Item
              name={"brand"}
              rules={[
                { required: true, message: "Please Enter Brand Name" },
                { max: 200, message: "Length is too much" },
              ]}
            >
              <Input placeholder="Brand Name" />
            </Form.Item>
            <div className="input-form-label">Manufactor</div>
            <Form.Item
              name={"manufacture"}
              rules={[
                { required: true, message: "Please Enter Manufacturer" },
                { max: 200, message: "Length is too much" },
              ]}
            >
              <Input placeholder="Manufactor" />
            </Form.Item>
            <Row>
              <Col sm={6}>
                <div className="input-form-label">Product SKU *</div>
                <Form.Item
                  name={"sku"}
                  rules={[
                    { required: true, message: "Please Enter SKU" },
                    {
                      max: 16,
                      message: "Product SKU length must be below 16 chars",
                    },
                    {
                      min: 10,
                      message:
                        "Product SKU length must be atleast 10 characters",
                    },
                  ]}
                >
                  <Input placeholder="sku" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Barcode</div>
                <Form.Item
                  name={"bar_code"}
                  rules={[
                    { required: true, message: "Please Enter Barcode" },
                    {
                      max: 16,
                      message: "Product SKU length must be below 16 chars",
                    },
                    {
                      min: 10,
                      message:
                        "Product SKU length must be atleast 10 characters",
                    },
                  ]}
                >
                  <Input placeholder="Barcode" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="input-form-label">Purchase Price</div>
                <Form.Item
                  name={"purchase_rate"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Purchase rate of the Product",
                    },
                  ]}
                >
                  <Input placeholder="0.00" type="number" max={1000000} />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Retail Price</div>
                <Form.Item
                  name={"retail_rate"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Reail Rate of the product",
                    },
                  ]}
                >
                  <Input placeholder="0.00" type="number" max={1000000} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="input-form-label">Imperial Unit</div>
                <Form.Item
                  name={"unitId"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Imperial Unit.",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    className="border rounded"
                    allowClear
                    placeholder="Select  Imperial Unit"
                  >
                    {unit?.map((item: any, index: number) => (
                      <Select.Option key={index} value={Number(item.id)}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Unit</div>
                <Form.Item
                  name={"unit"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Available Units.",
                    },
                  ]}
                >
                  <Input placeholder="Unit" type="number" max={10000} />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Available Unit</div>
                <Form.Item
                  name={"units"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Available units for Bulk Order",
                    },
                  ]}
                >
                  <Input
                    placeholder="Available Units"
                    max={10000}
                    type="number"
                    prefix={0}
                  />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Accept bulk orders</div>
                <Form.Item
                  name={"bulk_order"}
                  rules={[
                    {
                      required: true,
                      message:
                        "Please Select if you suppor Bulk order for this Product",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    className="border rounded"
                    allowClear
                    onChange={categoryChangeHandler}
                    placeholder="Select Bulk Order"
                  >
                    <Select.Option value={"accept"}>Accept</Select.Option>
                    <Select.Option value={"notaccept"}>
                      Not Accept
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Product Status</div>
                <Form.Item
                  name={"status"}
                  rules={[
                    {
                      required: true,
                      message: "Please Choose the Status of The product",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    className="border rounded"
                    allowClear
                    onChange={categoryChangeHandler}
                    placeholder="Select Status"
                  >
                    <Select.Option value={"available"}>Available</Select.Option>
                    <Select.Option value={"notavailable"}>
                      Not Available
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="input-form-label">
          Product Description and specifications
        </div>
        <div style={{ height: "270px" }}>
          <ReactQuill
            theme="snow"
            value={specifications}
            onChange={setSpecifications}
            style={{ height: "220px" }}
          />
        </div>

        <br />
        <Row>
          <Col sm={6}></Col>
          <Col sm={2}>
            {/* <Button size="large" block onClick={() => props?.onBack()}>
              Back
            </Button> */}
          </Col>
          <Col sm={4}>
            <Form.Item>
              <Button size="large" block type="primary" htmlType="submit">
                Continue
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
export default forwardRef(Information);
