import React, { useEffect, useRef, useState } from "react";
import { Modal, Select } from "antd";
import { Button, Form, Input, notification } from "antd";
import { POST, PUT } from "../../utils/apiCalls";
import API from "../../config/API";
import useFetch from "../../shared/hook/fetchData";
import ImagePicker from "../components/ImagePicker";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const UnitModal = (props: any) => {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const update = "update";
  const type = props?.type;
  const fileInputRef = useRef(null);
  const { Option } = Select;
  const { data: category, isLoading: loading1 } = useFetch(API.CATEGORY, false);


  useEffect(() => {
    console.log('propss',props)
    if (props?.type == update) {
      form.setFieldsValue({
        name: props?.data?.name,
        symbol: props?.data?.symbol
      });
    } else {
      form.resetFields();
    }
  }, [props?.data, props?.type]);
  const formSubmitHandler = async (values: any) => {
    setIsLoading(true);
    try {
     
      const obj = {
        name: values?.name,
        symbol: values?.symbol,
      };
      const response: any =
        props?.type === update
          ? await PUT(API.UNIT + props?.data?.id, obj)
          : await POST(API.CREATE_UNIT, obj);
      if (response?.status) {
        Notifications["success"]({
          message: "Success",
          description: `Successfully ${
            props?.type == update ? "Updated" : "Added"
          } the item`,
        });
        form.resetFields();
        props?.getUnit();
        props?.modalClose();
      } else {
        Notifications["error"]({
          message: ` ${
            response?.message
          }`,
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: `Failed to ${
          props?.type == update ? "Update the Item" : "Add New Item"
        }`,
      });
    }
    setIsLoading(false);
  };
  return (
    <Modal
      title={`${props?.type == update ? "Update" : "Add New"} SubCategory`}
      open={props?.open}
      okText="Update"
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        props?.modalClose();
        form.resetFields();
      }}
    >
      {contextHolder}
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
        onFinish={formSubmitHandler}
      >
        <Form.Item
          label="Name"
          name={"name"}
          rules={[
            {
              required: true,
              message: "Please Enter Name",
            },
          ]}
        >
          <Input placeholder="input Name" />
        </Form.Item>
        <Form.Item
          label="Symbol"
          name={"symbol"}
          rules={[
            {
              required: true,
              message: "Please Enter Symbol",
            },
          ]}
        >
          <Input placeholder="Symbol" />
        </Form.Item>

        
          <div className="d-flex gap-2 justify-content-end">
            <Button
              onClick={() => {
                props?.modalClose();
                form.resetFields()
              }}
            >
              Cancel
            </Button>
            <Button type="primary" loading={isLoading} onClick={form.submit}>
              {props?.type == "add" ? "Add" : "Update"}
            </Button>
          </div>
       
      </Form>
    </Modal>
  );
};

export default UnitModal;
