import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import DashboardProduct from "./components/product";
import DashBoardDataTable from "./components/dataTable";
import DashBoardBarchart from "./components/barchart";
import DashboardPiechart from "./components/piechart";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import { notification } from "antd";
import DashBoardBarchartUsers from "./components/barchart2";

type ResponseType = {
  data?: any[];
  totalOrders: number;
  totalProducts: number;
  totalSellers: number;
  totalUsers: number;
  statusCode: number;
  message: string;
  status: boolean;
  orders: any[];
  users: any[];
};

function Dashboard() {
  const [Notifications, contextHolder] = notification.useNotification();
  const [users, setUsers] = useState<any[]>([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalSellers, setTotalSellers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<any[]>([]);
  const [users7, setUsers7] = useState<any[]>([]);
  const getDashboardInfo = async () => {
    const url = API.STORE_ADMIN_DASHBOARD;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setTotalUsers(response.totalUsers);
        setTotalSellers(response.totalSellers);
        setTotalProducts(response.totalProducts);
        setTotalOrders(response.totalOrders);
        setUsers(response.data);
        setOrders(response?.orders);
        setUsers7(response?.users);
      } else {
        Notifications["error"]({
          message: response.message ?? "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: `Something went wrong.`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardInfo();
  }, []);
  return (
    <Container>
      {contextHolder}
      <Row>
        <Col md={12}>
          <div className="dashboard-Box1">
            <DashboardProduct
              totalUsers={totalUsers}
              totalSellers={totalSellers}
              totalProducts={totalProducts}
              totalOrders={totalOrders}
            />
          </div>
        </Col>
        <Col md={8}>
          <div className="dashboard-Box2">
            <DashBoardDataTable data={users} loading={loading} />
          </div>
          <br />
        </Col>
        <Col md={4}>
          <div className="dashboard-Box3">
            <DashBoardBarchart orders={orders} />
          </div>
          <div className="dashboard-Box3">
            <DashBoardBarchartUsers users={users7} />
          </div>
          <br />
          <br />
          {/* <div className="dashboard-Box4">
            <DashboardPiechart />
          </div> */}
        </Col>
      </Row>
    </Container>
  );
}
export default Dashboard;
