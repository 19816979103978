import React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Rate } from "antd";
import { FaStar } from "react-icons/fa6";

function ProductItem(props: any) {
  const navigate = useNavigate();
  const Settings = useSelector((state: any) => state.Settings.Settings);

  return (
    <div
      className={`ProductItem`}
      onClick={() => {
        navigate(`/product/${props?.item?.slug}?pid=${props?.item?.pid}`);
      }}
    >
      <div className="ProductItem-Box1">
        <img
          src={props?.item?.image}
          className="ProductItem-img"
          alt="ProductItem-img"
        />
      </div>

      <div className="ProductItem-Box2">
        <div className="ProductItem-txt1 ">{props?.item?.name}</div>
        <div className="ProductItem-txt2">{props?.item?.description}</div>
        {props?.item?.unit <= 0 ? (
          <div className="ProductItem-txt3 text-danger">Out of stock</div>
        ) : (
          <>
            <div className="ProductItem-txt3">
              {props?.item?.retail_rate?.toFixed(2)} {Settings.currency} / {props?.item?.unitDetails?.name}
            </div>
            <div className="ProductItem-txt2 text-danger fw-medium">
              {props?.item?.unit <= 5 ? ` only ${props?.item?.unit} left` : ""}
            </div>
          </>
        )}

        {props?.item?.averageRating ? (
          <span
            className=" rounded px-1"
            style={{ color: "black", fontWeight: "400" }}
          >
            <span style={{ fontSize: "12px" }}>
              {Number(props?.item?.averageRating)?.toFixed(1)}
            </span>{" "}
            <FaStar size={12} color="#f5da42" />{" "}
            <span className="ProductItem-txt5">
              {props?.item?.totalReviews
                ? `(${props?.item?.totalReviews})`
                : ""}
            </span>
          </span>
        ) : null}
      </div>
    </div>
  );
}
export default ProductItem;
