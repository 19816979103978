import { Outlet, Navigate } from "react-router-dom";
import React from "react";
function ProtectedRouteAdminOnly({ role }: { role: string }) {
  if (role == "admin") {
    return <Outlet />;
  } else {
    return <Navigate to="/auth/overview" replace />;
  }
}
export default ProtectedRouteAdminOnly;
