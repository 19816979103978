import { Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Loading from "../../components/loading";
import NoData from "../../components/noData";
import { useParams, useSearchParams } from "react-router-dom";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import StoreAllProducts from "../../components/storeAllProducts/storeAllProducts";
import InfiniteScroll from "react-infinite-scroll-component";
import SkelotonProductLoading from "./skeloton";
import _ from "lodash";
function StoreFront() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const pageSize = 10;
  const storeId = params.store;

  const getProductsByStore = async (current: number = page) => {
    const url =
      API.STORE_SEARCH_GETALL +
      storeId +
      `?order=ASC&page=${current}&take=${pageSize}`;
    if (storeId) {
      try {
        const response: any = await GET(url, null);
        if (response.status) {
          setProducts((prod) => _.uniqBy([...prod, ...response?.data], "_id"));
          setMeta(response?.meta);
        } else throw new Error(response.message);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  };

  const changePage = async (page: number) => {
    await getProductsByStore(page);
    setPage(page);
  };

  useEffect(() => {
    getProductsByStore(page);
  }, []);

  return (
    <div className="mt-3">
      {loading ? (
        <SkelotonProductLoading count={18} />
      ) : products.length ? (
        <InfiniteScroll
          style={{ margin: "0px", padding: "0px", overflow: "initial" }}
          dataLength={products.length}
          next={() => {
            changePage(page + 1);
          }}
          hasMore={meta?.hasNextPage ?? false}
          loader={<SkelotonProductLoading />}
          endMessage={
            <p className="fw-bold text-center mt-3">
              {products?.length > 18
                ? `Showing ${meta?.itemCount} of ${meta?.itemCount} Categories`
                : ""}
            </p>
          }
        >
          {products.map((item: any) => (
            <StoreAllProducts data={item} />
          ))}
        </InfiniteScroll>
      ) : (
        <NoData header={"No Products Available"} />
      )}
      {/* <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div> */}
    </div>
  );
}

export default StoreFront;
