import React, { useState } from "react";
import { Modal, Button, notification } from "antd";
import { useParams } from "react-router-dom";
import API from "../../../config/API";
import { PUT } from "../../../utils/apiCalls";

const ActivateUserModal = (props: any) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const handleActivate = async () => {
    try {
      setLoading(true);
      const url = API.USER_REACTIVATE + params?._id;
      const body = {};
      const response: any = await PUT(url, body);
      if (response.status) {
        notificationApi.success({
          message: `User Account has been Activated Successfully`,
        });
        props?.onCancel();
      } else {
        notificationApi.error({ message: `Activation failed` });
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          notificationApi.error({
            message: `Activation failed: User not found`,
          });
        } else if (error.response.status === 500) {
          notificationApi.error({ message: `Activation failed: Server error` });
        } else {
          notificationApi.error({
            message: `Activation failed: ${error.message}`,
          });
        }
      } else if (error.request) {
        notificationApi.error({
          message: `Activation failed: No response received from the server`,
        });
      } else {
        notificationApi.error({
          message: `Activation failed: ${error.message}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        visible={props.visible}
        onCancel={props.Cancel}
        footer={[
          <Button key="cancel" onClick={props.onCancel}>
            Cancel
          </Button>,
          <Button
            key="activate"
            type="primary"
            onClick={handleActivate}
            loading={loading}
          >
            Activate
          </Button>,
        ]}
      >
        <p>Do you want to activate this user's account?</p>
      </Modal>
    </>
  );
};

export default ActivateUserModal;
