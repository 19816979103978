import { Input, Button, Form, notification } from "antd";
import React from "react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { IoMailOutline } from "react-icons/io5";
import { PiPhoneCall } from "react-icons/pi";
import API from "../../config/API";
import { POST } from "../../utils/apiCalls";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Faq from "../faq";

function NeedHelp() {
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [notificationApi, contextHolder] = notification.useNotification();
  const OnFinsh = async (values: any) => {
    console.log(values, "values");

    try {
      let url = API.ENQUIRY_CREATE;
      let Obj = {
        email: values?.email,
        message: values?.message,
      };
      let response: any = await POST(url, Obj);
      console.log("response", response);
      if (response.status) {
        notificationApi.success({message:"succesfully added"});
      }
    } catch (error) {
      console.error("error");
    }
  };
  
  return (
    <div className="Screen-box">
      {contextHolder}
      <Row>
        <Col md={8}>
            <Faq/>
        </Col>
        <Col md={4} className="mt-3 px-4">
        <h3>Connect With Us</h3>
        <a className="socialMediaContainer" href="#">
          <a href={`https://wa.me/${Settings?.contactNumber}`} target="_blank">
            <AiOutlineWhatsApp size={32} color="green" />
          </a>
          <span>Sent Us on Whatsapp</span>
        </a>
        <a className="socialMediaContainer" href="#">
          <a href={`tel:${Settings?.contactNumber}`}>
            <PiPhoneCall size={32} color="#3c4b9e" />
          </a>
          <span>Custommer Support</span>
        </a>
        <a className="socialMediaContainer" href="#">
          <a href={`mailto:${Settings?.contactEmail}`}>
            <IoMailOutline size={32} color="#000" />
          </a>
          <span>Draft an Email</span>
        </a>
        <Form className="customMsg" onFinish={OnFinsh}>
          <Form.Item name="email">
            <Input
              size="large"
              placeholder="Enter Email"
              className="mb-3"
              required
            />
          </Form.Item>
          <Form.Item name="message">
            <Input.TextArea
              required
              size="large"
              rows={3}
              placeholder="Type your message..."
            //   className="message-input mb-3"
            />
          </Form.Item>
          <div className="FloatingButton-Btn1">
            <Button
              type="primary"
              className="send-button"
              htmlType="submit"
              size="large"
              style={{ width: "60%" }}
            >
              Send
            </Button>
          </div>
        </Form>
        </Col>
      </Row>
    </div>
  );
}
export default NeedHelp;
