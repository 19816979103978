import React, { useState } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import { Col, Container, Row } from "react-bootstrap";

import { Auth } from "../../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import API from "../../config/API";
import { DOCUMENT_UPLOAD, POST } from "../../utils/apiCalls";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/userSlice";
import { InputOTP } from "antd-input-otp";
import {
  FcInTransit,
  FcBullish,
  FcCustomerSupport,
  FcSalesPerformance,
  FcIphone,
} from "react-icons/fc";
import { TbTruckReturn } from "react-icons/tb";
import { BiErrorCircle } from "react-icons/bi";
import PrefixSelector from "../../components/prefixSelector";
import SummaryCard from "../signupScreen/summaryCard";
import SuccessModal from "../../components/successModal";
import "./styles.scss";
import FilePicker from "../sellerRegister/component/filePicker";
import dayjs from "dayjs";

function DriverRegister() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [successmodal, setSuccessmodal] = useState(false);
  const [datas, setdatas] = useState<any>({});
  const [verification, setverification] = useState(false);
  const [autho, setautho] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    if (!values?.id_proof?.file) {
      setError("Please Select Id Proof.");
      return;
    }
    setError(null);
    try {
      setdatas(values);
      setIsLoading(true);
      let recaptchas = await new RecaptchaVerifier(Auth, "recaptcha", {});
      let phone = `${values.code}${values.phone}`;
      let checkPhone: any = await signInWithPhoneNumber(
        Auth,
        phone,
        recaptchas
      );
      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setverification(true);
      } else {
        setError("Somehting went wrong");
      }
      setIsLoading(false);
    } catch (err) {
      setverification(false);
      console.log("LoginPhone = = = >", err);
      setIsLoading(false);
      setError("Somehting went wrong");
    }
  };

  const verifyOtp = async (values: any) => {
    try {
      setIsLoading(true);
      let otp = values.otp.join("");
      let verify = await autho.confirm(otp);
      const token = await verify?.user?.getIdToken();
      if (verify?.user?.phoneNumber) {
        Signup(token);
      }
    } catch (err) {
      setIsLoading(false);
      setError("Somehting went wrong");
      console.log("verifyOtp err", err);
    }
  };

  const Signup = async (token: string) => {
    try {
      const id_proof_upload = await DOCUMENT_UPLOAD(datas?.id_proof?.file);
      let url = API.SIGNUP;
      let body = {
        email: datas.email,
        username: datas.username,
        password: datas.password,
        first_name: datas?.firstname,
        last_name: datas?.lastname,
        name: `${datas?.firstname} ${datas?.lastname}`,
        countrycode: datas?.code,
        phone: datas?.phone,
        image: null,
        type: "driver",
        idToken: token,
        licenceNo: datas?.licenceNo,
        licenceImg: id_proof_upload,
        licenceExpiry: datas?.licenceExpiry,
      };
      var signupRes: any = await POST(url, body);
      if (signupRes.status) {
        dispatch(login(signupRes));
        window.location.replace("/home");
        setSuccessmodal(true);
        setTimeout(() => {
          setSuccessmodal(false);
        }, 5000);
        setIsLoading(false);
      } else {
        setError(signupRes.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError("Something went wrong");
      setIsLoading(false);
    }
  };

  const handleFileUpload = (file: any) => {
    setFile(file);
    form.setFieldsValue({ id_proof: file });
  };

  return (
    <div className="Screen-box ftscreen-fnt">
      <Container>
        <Row>
          <Col md={4}>
            {verification ? (
              <div className="mt-4">
                <SummaryCard data={datas} />
                <div className="text-center my-3">
                  Enter OTP send to your mobile number
                </div>
                <Form onFinish={verifyOtp} layout="vertical">
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Input 6 digit verification code !",
                      },
                    ]}
                  >
                    <InputOTP autoFocus inputType="numeric" length={6} />
                  </Form.Item>
                  {error ? (
                    <div className="driverRegister-errortxt">
                      <BiErrorCircle /> &nbsp;
                      {error} .Try again
                    </div>
                  ) : null}
                  <Button
                    block
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                    style={{ height: 45 }}
                  >
                    Verify
                  </Button>
                </Form>
              </div>
            ) : (
              <>
                <div className="mt-4">
                  <h2 className="fs-2">Register as a Driver</h2>
                </div>
                <br />
                <Form
                  onFinish={onFinish}
                  initialValues={{ code: "+91" }}
                  form={form}
                >
                  <Row>
                    <Col sm={6} xs={6}>
                      <Form.Item
                        name={"firstname"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter firstname",
                          },
                        ]}
                      >
                        <Input placeholder="First Name" size="large" />
                      </Form.Item>
                    </Col>
                    <Col sm={6} xs={6}>
                      <Form.Item
                        name={"lastname"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter lastname",
                          },
                        ]}
                      >
                        <Input placeholder="Last Name" size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number",
                      },
                      { min: 8, message: "Enter a valid phone Number" },
                      { max: 16, message: "Enter a valid phone number" },
                    ]}
                  >
                    <Input
                      addonBefore={<PrefixSelector />}
                      style={{ width: "100%" }}
                      size="large"
                      placeholder="Enter Phone Number"
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onKeyPress={(e) => {
                        const isNumber = /^[0-9]*$/;
                        if (!isNumber.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email id",
                      },
                      {
                        type: "email",
                        message: "Please enter valid email id",
                      },
                      {
                        max: 50,
                        message: "Please enter valid email id",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Email Address" size="large" />
                  </Form.Item>
                  <Form.Item
                    name={"username"}
                    rules={[
                      { required: true, message: "Please enter username" },
                      {
                        max: 20,
                        message: "Please enter valid email id",
                      },
                    ]}
                  >
                    <Input placeholder="User name Eg:Jhon@123" size="large" />
                  </Form.Item>

                  <Form.Item
                    name={"password"}
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        min: 8,
                        message: "Password must be minimum 8 characters.",
                      },
                      {
                        max: 16,
                        message:
                          "Password must contain maximum of 16 characters",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password size="large" placeholder="Enter Password" />
                  </Form.Item>
                  <Form.Item
                    name={"confirm"}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                    dependencies={["password"]}
                    hasFeedback
                  >
                    <Input.Password
                      size="large"
                      placeholder="Confirm Password"
                    />
                  </Form.Item>
                  <Form.Item
                    name={"licenceNo"}
                    rules={[
                      { required: true, message: "Please enter Licence No" },
                      {
                        max: 30,
                        message: "Please enter a Valid Licence Number",
                      },
                      {
                        min: 6,
                        message: "Please enter a Valid Licence Number",
                      },
                    ]}
                  >
                    <Input placeholder=" Licence No" size="large" />
                  </Form.Item>
                  <div className="input-form-label">
                    Select Driver's Licence proof
                  </div>
                  <Form.Item
                    name="id_proof"
                    rules={
                      [
                        // { required: true, message: "Please Upload ID Proof" },
                      ]
                    }
                  >
                    <FilePicker
                      onSubmit={handleFileUpload}
                      fileName={file?.file}
                    />
                  </Form.Item>
                  <div className="input-form-label">Licence Expiry</div>
                  <Form.Item
                    name="licenceExpiry"
                    rules={[
                      {
                        required: true,
                        message: "Please select Licence Expiry date.",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Licence Expiry"
                      style={{ width: "100%" }}
                      size="large"
                      disabledDate={(current) => {
                        return current && current < dayjs().startOf("day");
                      }}
                    />
                  </Form.Item>

                  {error ? (
                    <div className="driverRegister-errortxt">
                      <BiErrorCircle /> &nbsp;
                      {error} .Try again
                    </div>
                  ) : null}
                  <div id="recaptcha"></div>
                  <Button
                    className="mb-4"
                    block
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                    style={{ height: 45 }}
                  >
                    Create Account
                  </Button>
                </Form>
              </>
            )}
          </Col>
          <Col md={8}>
            <div className="driverRegister-box2 mt-4">
              <h4 className="">Why Drive for ROCKANA?</h4>
              <div className="driverRegister-text1">
                Join ROCKANA as a driver and play a crucial role in delivering a
                wide variety of rocks and sand materials to our customers. Your
                contribution ensures timely deliveries, excellent service, and
                customer satisfaction.
              </div>
              <Row className="mt-3 text-start gy-3">
                <Col md="4">
                  <FcInTransit size={35} />
                  <div className="driverRegister-text2 mt-3">
                    Deliver Across Regions
                  </div>
                  <div className="driverRegister-text1">
                    Reach customers across various locations, ensuring the
                    timely delivery of rocks and sand materials.
                  </div>
                </Col>
                <Col md="4">
                  <FcBullish size={35} />
                  <div className="driverRegister-text2 mt-3">
                    Competitive Earnings
                  </div>
                  <div className="driverRegister-text1">
                    Enjoy competitive earnings with transparent pay structures
                    and zero deductions from your earnings.
                  </div>
                </Col>
                <Col md="4">
                  <FcCustomerSupport size={35} />
                  <div className="driverRegister-text2 mt-3">
                    24x7 Driver Support
                  </div>
                  <div className="driverRegister-text1">
                    Our dedicated Driver Support Team is ready to assist you
                    with any queries or concerns.
                  </div>
                </Col>
                <Col md="4">
                  <FcSalesPerformance size={35} />
                  <div className="driverRegister-text2 mt-3">
                    Prompt Payments
                  </div>
                  <div className="driverRegister-text1">
                    Receive payments promptly, with processing times as fast as
                    7-10 days from successful deliveries.
                  </div>
                </Col>
                <Col md="4">
                  <FcIphone size={35} />
                  <div className="driverRegister-text2 mt-3">
                    On-the-Go Operations
                  </div>
                  <div className="driverRegister-text1">
                    Manage your delivery operations seamlessly with the ROCKANA
                    Driver App, accessible anywhere, anytime.
                  </div>
                </Col>
                <Col md="4">
                  <TbTruckReturn color="orange" size={35} />
                  <div className="driverRegister-text2 mt-3">
                    Low Return Incidents
                  </div>
                  <div className="driverRegister-text1">
                    Experience stress-free deliveries with our minimal and
                    affordable return charges.
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      {successmodal ? (
        <SuccessModal
          visible={successmodal}
          onCancel={() => setSuccessmodal(false)}
          title="success"
          body="Account created successfully"
          onButtonClick={() => setSuccessmodal(false)}
          buttonText="Go Back"
        />
      ) : null}
    </div>
  );
}

export default DriverRegister;
