import React, { useEffect, useState } from "react";

const MenuRoles = () => {
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [roles, setRoles] = useState([]);
  const [menus, setMenus] = useState([]);
  return <div>MenuRoles</div>;
};

export default MenuRoles;
