import React, { useEffect, useState } from "react";
import NoData from "../../../components/noData";
import Loading from "../../../components/loading";
import { BsFillBookmarkFill } from "react-icons/bs";
import API from "../../../config/API";
import { useSelector } from "react-redux";
import { DELETE, GET } from "../../../utils/apiCalls";
import { Avatar, List, Pagination, Popconfirm, notification } from "antd";
import { useNavigate } from "react-router-dom";

const ProfileFavourites = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<any>();
  const User = useSelector((state: any) => state.User.user);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const navigate = useNavigate();
  useEffect(() => {
    getFavourites();
  }, []);
  const getVariantInfo = (data: any) => {
    let variantss = "";
    if (Array.isArray(data?.combination) == true) {
      data?.combination.map((item: any) => {
        variantss += `${item.value} `;
      });
    }
    return variantss.length ? `${variantss}` : variantss;
  };
  const getFavourites = async (currPage: number = page) => {
    const url =
      API.WISHLIST_GETALL + `?order=ASC&page=${currPage}&take=${pageSize}`;
    setIsLoading(true);
    try {
      const favorites: any = await GET(url, null);
      if (favorites.status) {
        setOrders(favorites?.data);
        setMeta(favorites?.meta);
      } else {
        notificationApi.error({ message: favorites.message ?? "" });
      }
    } catch (err) {
      notificationApi.error({ message: `Failed to get Favorites.` });
    } finally {
      setIsLoading(false);
    }
  };
  const deleteItem = async (item: any) => {
    const url = API.WISHLIST + item?.id;
    try {
      const deleted: any = await DELETE(url);
      console.log(deleted);
      if (deleted.status) {
        getFavourites(page);
        notificationApi.success({ message: `Item removed from wishlist.` });
      } else {
        notificationApi.error({ message: deleted?.message });
      }
    } catch (err) {
      console.log(err);
      notificationApi.error({ message: `something went wrong!` });
    }
  };
  const changePage = async (page: number) => {
    window.scrollTo(0, 0);
    await getFavourites(page);
    setPage(page);
  };
  const navigateOrder = (item: any) => {
    navigate(
      `/product/${item?.slug}?pid=${item?.pid}${
        item?.variantId ? `&vid=${item?.variantId}` : ""
      }`
    );
  };
  return (
    <>
      <div className="mb-3">
        {/* <PageHeader title="Wishlist"></PageHeader> */}
        {contextHolder}
        <div className="profile-txt1">{`My Wishlist (${
          meta.itemCount ?? 0
        })`}</div>
        {isLoading ? (
          <Loading />
        ) : orders && orders.length ? (
          <List
            itemLayout="horizontal"
            className="order-list-item"
            dataSource={orders}
            renderItem={(item: any, index) => (
              <List.Item
                style={{ cursor: "pointer" }}
                actions={[
                  <Popconfirm
                    placement="bottomRight"
                    title={"Are you sure to Remove item from wishlist?"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={async () => await deleteItem(item)}
                  >
                    <a key="list-loadmore-edit" className="text-danger">
                      Delete
                    </a>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item?.image ?? ""}
                      shape="square"
                      size={80}
                      onClick={() => navigateOrder(item)}
                    />
                  }
                  title={
                    <div onClick={() => navigateOrder(item)}>
                      <span style={{ textTransform: "capitalize" }}>
                        {item?.name ?? ""} {getVariantInfo(item)}
                      </span>
                    </div>
                  }
                  description={
                    <div onClick={() => navigateOrder(item)}>
                      <p className="mb-0">
                        {item?.description && item?.description.length > 50
                          ? item?.description.slice(0, 50) + "..."
                          : item?.description}
                      </p>

                      {item?.status == false || item?.unit == 0 ? (
                        <h6 className="mt-0 text-danger">Out of Stock</h6>
                      ) : (
                        <h6 className="mt-0 text-dark">
                          {Number(item?.price)?.toFixed(2)}{" "}
                          {Settings.currency ?? ""}
                        </h6>
                      )}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <NoData
            icon={<BsFillBookmarkFill size={70} color="#e6e6e6" />}
            header="No Favorites"
            text1={`You have no items in your favourites`}
          />
        )}
        <div className="d-flex justify-content-center mt-3">
          <Pagination
            current={page || 1}
            pageSize={pageSize || 10}
            total={meta?.itemCount || 0}
            defaultCurrent={1}
            responsive={true}
            defaultPageSize={pageSize || 10}
            disabled={false}
            hideOnSinglePage={true}
            onChange={(current: any, size: any) => {
              changePage(current);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileFavourites;
