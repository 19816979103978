import React, { useEffect } from 'react';
import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import PageHeader from "../../components/pageHeader";
import API from "../../config/API";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt m-2">
        <PageHeader text="Privacy Policy" />
        <h5>Privacy Policy</h5>
        <h5 className="mt-4">Introduction</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          At {API.NAME}, we respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and share information when you use our services, including our website and mobile app.
        </div>

        <h5 className="mt-4">Information We Collect</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          We collect various types of information in connection with the services we provide, including:
        </div>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
            <ul>
                <li><strong>Personal Information:</strong> When you create an account, we may collect your name, email address, phone number, and other contact details. If you choose to log in using Google, we will also collect information associated with your Google account.</li>
                <li><strong>Payment Information:</strong> We collect your payment details, such as credit card information, when you make a purchase.</li>
                <li><strong>Usage Information:</strong> We collect information about how you use our services, including your IP address, browser type, and device information.</li>
                <li><strong>Location Information:</strong> We may collect and process information about your location when you use our services to ensure accurate delivery of materials.</li>
          </ul>
        </div>

        <h5 className="mt-4">How We Use Your Information</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          We use the information we collect to:
        </div>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
            <ul>
                <li>Provide, operate, and maintain our services.</li>
                <li>Process transactions and send related information, such as order confirmations and invoices.</li>
                <li>Communicate with you, including sending updates, security alerts, and support messages.</li>
                <li>Improve our services, develop new features, and personalize your experience.</li>
                <li>Monitor and analyze trends, usage, and activities in connection with our services.</li>
                <li>Ensure compliance with legal obligations and enforce our terms and conditions.</li>
            </ul>
        </div>

        <h5 className="mt-4">Sharing Your Information</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          We may share your information with third parties in the following circumstances:
        </div>
          <div className="d-flex p-2" style={{ textAlign: "justify" }}>
            <ul>
                <li><strong>Service Providers:</strong> We may share your information with vendors and service providers who need access to your information to perform services on our behalf.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                <li><strong>Business Transfers:</strong> In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company, your information may be transferred to the new owners.</li>
                <li><strong>With Your Consent:</strong> We may share your information with others if we have your consent to do so.</li>
            </ul>
          </div>

        <h5 className="mt-4">Your Choices and Rights</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          You have certain choices regarding the information we collect and how it's used, including:
        </div>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li>Accessing, updating, or deleting your information by logging into your account and adjusting your settings.</li>
            <li>Opting out of receiving promotional communications from us by following the instructions in those communications.</li>
            <li>Requesting the restriction of processing or objecting to the processing of your personal information.</li>
            <li>Requesting a copy of your personal data in a structured, machine-readable format.</li>
          </ul>
        </div>

        <h5 className="mt-4">Data Security</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          We take reasonable measures to protect your personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
        </div>

        <h5 className="mt-4">Changes to This Policy</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by email or through our services.
        </div>

        <h5 className="mt-4">Contact Us</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          If you have any questions or concerns about this Privacy Policy, please contact us at support@rockana.com.
        </div>
        
        <div className="mt-5">
          <SubscribeNewsletter />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
