import React, { useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Form, Space, Button, Input, Select, notification } from "antd";
import { DELETE, GET, POST, PUT } from "../../../utils/apiCalls";
import API from "../../../config/API";
import AdminLoading from "../../components/AdminLoading";
import NoData from "../../../components/noData";
import { isEqual } from "lodash";

const { Option } = Select;

const UnitForm: React.FC = () => {
  const [Notifications, contextHolder] = notification.useNotification();
  const [weightsData, setWeightsData] = useState<any[]>([]);
  const { control, handleSubmit, reset, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "weights",
  });
  const [deletedItemIds, setDeletedItemIds] = useState<number[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [existingData, setExistingData] = useState<number[]>([])

  useEffect(() => {
    fetchWeights();
    fetchUnits();
  }, []);

  const fetchWeights = async () => {
    try {
      const url = API.WEIGHT_CHARGE;
      const response: any = await GET(url, null);
      setWeightsData(response?.data);
      getExistingData(response?.data)
      reset({ weights: response.data });
      return response.data;
    } catch (error) {
      console.error("Error while loading data:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getExistingData = (data:any)=>{
    const unitIds = data.map((item:any) =>{
      return item?.unitId
    })
    setExistingData(unitIds)
  }

  const fetchUnits = async () => {
    try {
      const response: any = await GET(API.GET_ALL_UNITS, null);
      if (response?.status) {
        setUnits(response?.data);
      } else {
        console.log(response?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getExistingUnits = () => {
      const unitIds = existingData
      return units.filter((unit: any) => !unitIds.includes(unit?.id))
    
  };

  const onWeightFinish = async (values: any) => {
    setLoading(true);
    try {
      let response: any;
      const initialData = await fetchWeights();
      const newData = values.weights.map((item: any) => ({
        id: item.id,
        unitId: item.unitId,
        operator: item.operator,
        charge: item.charge,
      }));
      const modifiedRows = newData.filter((newItem: any) => {
        const initialItem = initialData.find(
          (item: any) => item.id === newItem.id
        );
        return !isEqual(newItem, initialItem);
      });

      const updatedRows = modifiedRows.filter((item: any) => item.id);
      if (updatedRows.length > 0) {
        const updateUrl = API.WEIGHT_CHARGE;
        response = await PUT(updateUrl, modifiedRows);
      }
      const newRows = newData.filter((item: any) => !item.id);
      if (newRows.length > 0) {
        const createUrl = API.WEIGHT_CHARGE;
        response = await POST(createUrl, newRows);
      }
      if (deletedItemIds.length > 0) {
        const deleteUrls = deletedItemIds.map(
          (id) => `${API.WEIGHT_CHARGE}${id}`
        );
        const deleteRequests = deleteUrls.map((url) => DELETE(url));
        await Promise.all(deleteRequests);
        const updatedSlabsData = weightsData.filter(
          (item) => !deletedItemIds.includes(item.id)
        );
        setWeightsData(updatedSlabsData);
        setDeletedItemIds([]);
      }

      Notifications.success({
        message: "Submission Successful",
        description: "The weight charge has been successfully submitted.",
      });
      fetchWeights();
    } catch (error) {
      console.error("Submission Error:", error);

      Notifications.error({
        message: "Submission Error",
        description: "An error occurred while submitting the data.",
      });
    } finally {
      setLoading(false);
    }
  };

  const getUnitName = (id: number) => {
    const unit: any = units.find((unit: any) => unit.id == id);
    return unit ? unit?.name : undefined;
  };

  const selectedValue = (value:any,index:any)=>{
    const updatedData = [...existingData, value]
    setExistingData(updatedData)
    setValue(`weights[${index}].unitId`, value);
  }

  return (
    <div>
      {contextHolder}
      {isLoading ? (
        <AdminLoading />
      ) : (
        <form name="form" onSubmit={handleSubmit(onWeightFinish)}>
          {fields.map((field, index) => (
            <Space key={field.id} style={{ display: "flex", marginBottom: 8 }}>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    style={{ width: 175 }}
                    placeholder="Unit"
                    value={field.value ? getUnitName(field.value) : field.value}
                    onChange={(val)=>{selectedValue(val,index)}}
                  >
                    {getExistingUnits().map((item: any) => {
                      return <Option value={item?.id}>{item?.name}</Option>;
                    })}
                  </Select>
                )}
                control={control}
                name={`weights[${index}].unitId`}
                rules={{ required: "Missing unit" }}
              />
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    style={{ width: 60 }}
                    defaultValue={field.value}
                  >
                    <Option value="=">=</Option>
                    {/* <Option value="<=">&lt;=</Option>
                    <Option value=">=">&gt;=</Option> */}
                  </Select>
                )}
                control={control}
                name={`weights[${index}].operator`}
                rules={{ required: "Missing operator" }}
              />
              <Controller
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Price"
                    defaultValue={field.value}
                  />
                )}
                control={control}
                name={`weights[${index}].charge`}
                rules={{ required: "Missing price" }}
              />
              <DeleteOutlined
                onClick={() => {
                  const deletedItemId = weightsData[index]?.id;
                  const deletingData = existingData[index]
                  remove(index);
                  setDeletedItemIds((prevIds) => [...prevIds, deletedItemId]);
                  const updatedData = existingData.filter(item => item !== deletingData)
                  setExistingData(updatedData)
                }}
              />
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => append({})}
              icon={<PlusOutlined />}
            >
              Add Unit Charge
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit Unit
            </Button>
          </Form.Item>
        </form>
      )}
    </div>
  );
};

export default UnitForm;
