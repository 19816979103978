import React, { useEffect, useState } from "react";
import API from "../../../../config/API";
import { GET } from "../../../../utils/apiCalls";
import { notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { Image } from "antd";

function DriverDetailsPage(props: any) {
  const [driver, setDriver] = useState<any>({});
  const [Notifications, contextHolder] = notification.useNotification();

  const keys = [
    "name",
    "email",
    "phone",
    "mail_verify",
    "phone_verify",
    "licenceNo",
    "licenceExpiry",
  ];
  const values: any = {
    name: "Name",
    email: "Email",
    phone: "Phone",
    mail_verify: "Email Verified",
    phone_verify: "Phone Verified",
  };
  const getDriverInfo = async () => {
    const url = API.GET_DRIVER_INFO + props?.id;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setDriver(response?.data);
      } else {
        Notifications["error"]({
          message: response?.message,
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong",
        description: "",
      });
    }
  };

  useEffect(() => {
    getDriverInfo();
  }, []);
  return (
    <>
      {contextHolder}
      {Object.keys(driver)?.map((item: any) => {
        if (keys.includes(item)) {
          return (
            <Row className="mx-0">
              <Col className="col-3">{values[item] ?? item}</Col>
              <Col className="col-9">
                {driver[item] == true
                  ? "Yes"
                  : driver[item] == false
                  ? "No"
                  : driver[item]}
              </Col>
            </Row>
          );
        }
      })}
      <Row>
        <Col md="4">
          <Image width={100} src={driver?.licenceImg} />
        </Col>
      </Row>
    </>
  );
}

export default DriverDetailsPage;
