import { useEffect, useState } from "react";
import { Button, Card, Collapse, Image, Tag } from "antd";

import { Col, Row } from "react-bootstrap";
import React from "react";
import Meta from "antd/es/card/Meta";
import { useSelector } from "react-redux";
function Review(props: any) {
  window.scrollTo(0, 0);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [category, setCategory] = useState<string>();
  const [subCategory, setSubCategory] = useState<string>();

  useEffect(() => {
    findCategory();
  }, [props?.information]);
  const findCategory = () => {
    const categoryData = props?.categories?.find(
      (item: any) => item?.id == props?.information?.category
    );
    if (categoryData) {
      setCategory(categoryData?.name);
    }
    const subCategoryData = categoryData?.sub_categories?.find(
      (item: any) => item?._id == props?.information?.subCategory
    );
    if (subCategoryData) {
      setSubCategory(subCategoryData?.name);
    }
  };
  return (
    <div>
      <div style={{ minHeight: "64vh" }}>
        <Collapse defaultActiveKey={["4"]} bordered={false}>
          <Collapse.Panel header="Product Information" key="1">
            <Row>
              {props?.information?.status
                ? Object.keys(props?.information).map((item: any) => (
                    <React.Fragment key={item}>
                      <Col className="col-6">{item}</Col>
                      <Col className="col-6">
                        {item === "category" || item === "subCategory"
                          ? (item === "category"
                            ? `: ${category}`
                            : `: ${subCategory}`)
                          : `: ${String(props?.information[item])}`}
                      </Col>
                    </React.Fragment>
                  ))
                : null}
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header="Product Images" key="2">
            <Row>
              {Array.isArray(props?.images)
                ? props?.images.map((item: any) => (
                    <>
                      <Col className="col-md-3">
                        <Image
                          width={"100%"}
                          src={item?.url?.Location ?? item?.url}
                        />
                      </Col>
                    </>
                  ))
                : null}
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header="Product Variants" key="3">
            <Row>
              {Array.isArray(props?.variants?.variants)
                ? props?.variants?.variants?.map((item: any) => (
                    <Col md="2">
                      <Card
                        hoverable
                        style={{ width: "100%" }}
                        cover={
                          <img
                            alt="product variants"
                            src={item?.image?.url?.Location ?? item?.image?.url}
                          />
                        }
                      >
                        <Meta
                          title={`${item?.price} ${Settings?.currency ?? ""}`}
                          description={
                            <div>
                              {Array.isArray(item?.combination) == true ? (
                                <div>
                                  {item?.combination?.map((item: any) => {
                                    return (
                                      <Tag bordered={false}>
                                        <span>{`${item.variant}: ${item?.value} `}</span>
                                      </Tag>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          }
                        />
                      </Card>
                    </Col>
                  ))
                : null}
            </Row>
          </Collapse.Panel>
        </Collapse>
      </div>
      <br />
      <Row>
        <Col sm={6}></Col>
        <Col sm={2}>
          <Button size="large" block onClick={() => props.onBack()}>
            Back
          </Button>
        </Col>
        <Col sm={4}>
          <Button
            size="large"
            block
            type="primary"
            htmlType="submit"
            loading={props?.loading}
            onClick={() => props.onChange()}
          >
            Add Product
          </Button>
        </Col>
      </Row>
    </div>
  );
}
export default Review;
