import React, { useEffect } from 'react';
import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import PageHeader from "../../components/pageHeader";
import API from "../../config/API";

function RefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt m-2">
        <PageHeader text="Refund Policy" />
        <h5>Refund Policy</h5>
        <h5 className="mt-4">Introduction</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          At {API.NAME}, we strive to provide the best service possible. However, we understand that there may be circumstances where a refund is necessary. This policy outlines the conditions under which refunds are granted and the process for requesting a refund.
        </div>

        <h5 className="mt-4">Eligibility for Refunds</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li>Refunds are available for orders canceled before the materials are dispatched.</li>
            <li>If the materials delivered are defective or not as described, you may be eligible for a refund or replacement.</li>
            <li>Requests for refunds must be made within 14 days of delivery.</li>
          </ul>
        </div>

        <h5 className="mt-4">Non-Refundable Situations</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li>Orders that have already been dispatched and are in transit.</li>
            <li>Materials that have been used or altered after delivery.</li>
            <li>Orders canceled after the dispatch of materials.</li>
          </ul>
        </div>

        <h5 className="mt-4">How to Request a Refund</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li>Contact our customer support team at support@rockana.com with your order details and the reason for the refund request.</li>
            <li>Our team will review your request and notify you of the approval or rejection of your refund within 5 business days.</li>
            <li>If approved, the refund will be processed, and a credit will automatically be applied to your original method of payment within 10 business days.</li>
          </ul>
        </div>

        <h5 className="mt-4">Late or Missing Refunds</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li>If you haven’t received a refund yet, first check your bank account again.</li>
            <li>Contact your credit card company, it may take some time before your refund is officially posted.</li>
            <li>Next, contact your bank. There is often some processing time before a refund is posted.</li>
            <li>If you’ve done all of this and you still have not received your refund yet, please contact us at support@rockana.com.</li>
          </ul>
        </div>

        <h5 className="mt-4">Contact Us</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          If you have any questions about our Refund Policy, please contact us at support@rockana.com.
        </div>
        
        <div className="mt-5">
          <SubscribeNewsletter />
        </div>
      </div>
    </div>
  );
}

export default RefundPolicy;
