import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { GET } from "../../utils/apiCalls";
import API from "../../config/API";
import PageHeader from "../components/PageHeader";
import AdminLoading from "../components/AdminLoading";
import NoData from "../../components/noData";

function TripInfo() {
  const [tripDetails, setTripDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [Notifications, contextHolder] = notification.useNotification();

  const getTripDetails = async () => {
    setLoading(true);
    const url = API.TRIPS + params?.id;
    try {
      const response: any = await GET(url, null);
      setTripDetails(response?.data);
    } catch (err: any) {
      Notifications["error"]({
        message: "Something went wrong",
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTripDetails();
  }, []);

  const renderTripDetails = () => {
    if (loading) {
      return <AdminLoading />;
    }

    if (tripDetails) {
      return (
        <div className="ms-4">
          <Row className="mt-2">
            <Col md="4">
              <div className="">{"ID"}</div>
            </Col>
            <Col md="8">: {tripDetails?.id}</Col>
          </Row>
          <Row className="mt-2">
            <Col md="4">
              <div className="">{"Title"}</div>
            </Col>
            <Col md="8">: {tripDetails?.title}</Col>
          </Row>
          <Row className="mt-2">
            <Col md="4">
              <div className="">{"Destination"}</div>
            </Col>
            <Col md="8">: {tripDetails?.destination}</Col>
          </Row>
          <Row className="mt-2">
            <Col md="4">
              <div className="">{"Driver ID"}</div>
            </Col>
            <Col md="8">: {tripDetails?.driverId}</Col>
          </Row>
          <Row className="mt-2">
            <Col md="4">
              <div className="">{"Materials"}</div>
            </Col>
            <Col md="8">: {tripDetails?.materials.join(", ")}</Col>
          </Row>
        </div>
      );
    } else {
      return <NoData />;
    }
  };

  return (
    <>
      {contextHolder}
      <PageHeader title="Trip Details" />
      {renderTripDetails()}
    </>
  );
}

export default TripInfo;
