import React from "react";
import { Col, Row } from "react-bootstrap";
import { FcBullish, FcInTransit } from "react-icons/fc";
import { FcBearish } from "react-icons/fc";
import { MdTrolley } from "react-icons/md";
import API from "../../../config/API";
import "../style.scss";
const SettlementSummary = ({ order }: any) => {
  const cardData = [
    {
      title: "AMOUNT TO SETTLE",
      amount: order.amountToSettle,
      icon: <FcInTransit size={50} />,
    },
    {
      title: "TOTAL ORDERPRICE",
      amount: order?.totalOrderPrice,
      icon: <MdTrolley size={45} color={API.COLOR} />,
    },
    {
      title: "SETTLED AMOUNT",
      amount: order?.totalSettledPrice,
      icon: <FcBearish size={42} />,
    },
    {
      title: "SETTLEMENT PENDING",
      amount: `${order?.settlementPending ?? 0}`,
      icon: <FcBullish size={42} />,
    },
  ];
  return (
    <Row className="gy-3">
      {cardData.map((item: any) => (
        <Col key={item} md={3}>
          <div className="productsOverView-Boxsettlement">
            <div>
              <div className="productsOverView-Txt1settlement">
                {item.title}
              </div>

              <div className="productsOverView-Txt2settlement mt-3">
                {item.amount}
              </div>
            </div>
            <div></div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default SettlementSummary;
