import React, { useEffect, useState } from "react";
import API from "../../../../config/API";
import { GET } from "../../../../utils/apiCalls";
import { notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { Image } from "antd";

function ComplaintDetailsPage(props: any) {
   const complaint = props?.data
  const [Notifications, contextHolder] = notification.useNotification();

  const keys = [
    "complaint",
    "email",
    "phone" ,
    "status"
  ];
  const values: any = {
    complaint: "Complaint : ",
    email: "Email : ",
    phone: "Phone : " ,
    status: "Status"
  };
  return (
    <>
      {contextHolder}
      {Object.keys(complaint)?.map((item: any) => {
        let displayValue
        if (keys.includes(item)) {
            if(item === "phone") {
                displayValue = `${complaint.countryCode} ${complaint.phone}`
            }
            else {
                displayValue = complaint[item]
            }
          return (
            <Row className="mx-0">
              <Col className="col-3">{values[item] ?? item}</Col>
              <Col className="col-9">
                {displayValue == true
                  ? "Yes"
                  : displayValue == false
                  ? "No"
                  : displayValue}
              </Col>
            </Row>
          );
        }
      })}
    </>
  );
}

export default ComplaintDetailsPage;
