import { Pagination, Table } from "antd";
import moment from "moment";
import React, {
  useEffect,
  useState,
  forwardRef,
  Ref,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { getOrderStatus } from "../../../shared/helpers/getOrderStatus";
import { AiOutlineEye } from "react-icons/ai";
import API from "../../../config/API";
import { GET } from "../../../utils/apiCalls";
import AdminLoading from "../../components/AdminLoading";
import { useNavigate } from "react-router-dom";
export type OrderPageAdminRefType = {
  search: (value: string) => void;
};
function Orders(
  { storeId, setSearching }: any,
  ref: Ref<OrderPageAdminRefType>
) {
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [orders, setOrders] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const pageSize = 12;
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const getOrders = async (current: number = 1, search: string = "") => {
    const url =
      API.ORDER_GET_BYSTORE +
      storeId +
      `?order=DESC&page=${current}&take=${pageSize}&orderId=${search}&from=${""}&to=${""}`;
    try {
      setLoading(true);
      const response: any = await GET(url, null);
      if (response?.status) {
        setOrders(response?.data);
        setMeta(response?.meta);
      } else {
        setOrders([]);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      title: "OrderId",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "userId",
    },
    {
      title: "OrderDate", //
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item: any) => <span>{moment(item).format("MMM Do YYYY")}</span>,
    },
    {
      title: "Total", //
      dataIndex: "grandTotal",
      key: "grandTotal",
      render: (item: any) => (
        <span>
          {Number(item)?.toFixed(2)} {Settings.currency}
        </span>
      ),
    },
    {
      title: "Status", //
      dataIndex: "status",
      key: "status",
      render: (item: string) => <span>{getOrderStatus(item)}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <AiOutlineEye
            cursor="pointer"
            size={20}
            color="#a10244"
            onClick={() => {
              navigate(`/auth/Orders/${_record?.order_id}`);
            }}
          />
        </div>
      ),
    },
  ];
  useImperativeHandle(ref, () => ({
    search: async (value: string) => {
      setSearching(true);
      await getOrders(page, value);
      setSearching(false);
    },
  }));
  const changePage = async (active: number) => {
    await getOrders(active);
    setPage(active);
  };
  useEffect(() => {
    getOrders();
  }, []);
  return (
    <>
      {loading ? (
        <AdminLoading />
      ) : (
        <Table
          size="small"
          dataSource={orders}
          columns={columns}
          pagination={false}
        />
      )}

      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={meta?.itemCount}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
    </>
  );
}

export default forwardRef(Orders);
