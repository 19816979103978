import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { TruckDetailsType } from "../../shared/types/types"; 
import { notification } from "antd";
import { useParams } from "react-router-dom";
import { GET } from "../../utils/apiCalls";
import PageHeader from "../components/PageHeader";
import AdminLoading from "../components/AdminLoading";
import API from "../../config/API";
import NoData from "../../components/noData";

function TruckInfo() {
    const [truckDetails, setTruckDetails] = useState<TruckDetailsType | undefined>();
    const [Notifications, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const getTruckDetails = async () => {
        setLoading(true);
        const url = API.TRUCK_GETBYID + params?.id;
        try {
        const response: any = await GET(url, null);
            setTruckDetails(response);
        } catch (err: any) {
        Notifications["error"]({
            message: "Something went wrong",
            description: err.message,
          });
        console.error(err);
        } finally {
        setLoading(false);
        }
    };

  useEffect(() => {
    getTruckDetails();
  }, []);

  const renderTruckDetails = () => {
  if (loading) {
    return <AdminLoading />;
  }

  if (truckDetails?.name) {
    return (
      <div className="ms-4">
        <Row className="mt-2">
          <Col md="4">
            <div className="">{"ID"}</div>
          </Col>
          <Col md="8">: {truckDetails.id}</Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <div className="">{"Name"}</div>
          </Col>
          <Col md="8">: {truckDetails.name}</Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <div className="">{"Owner"}</div>
          </Col>
          <Col md="8">: {truckDetails.owner}</Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <div className="">{"Owner ID"}</div>
          </Col>
          <Col md="8">: {truckDetails.ownerId}</Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <div className="">{"Made By"}</div>
          </Col>
          <Col md="8">: {truckDetails.madeBy}</Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <div className="">{"Capacity"}</div>
          </Col>
          <Col md="8">: {truckDetails.capacity}</Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <div className="">{"Registration Number"}</div>
          </Col>
          <Col md="8">: {truckDetails.regNumber}</Col>
        </Row>
        <Row className="mt-2">
          <Col md="4">
            <div className="">{"Manufacture Year"}</div>
          </Col>
          <Col md="8">: {truckDetails.manufactureYear}</Col>
        </Row>
      </div>
    );
  } else {
    return <NoData />;
  }
};

return (
  <>
  {contextHolder}
    <PageHeader title="Truck Details" />
    {renderTruckDetails()}
  </>
);

}

export default TruckInfo;
