import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { POST } from "../../utils/apiCalls";
import API from "../../config/API";
import { Button, Form, Input, notification } from "antd";
import { Container, Row, Col } from "react-bootstrap";

function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigation = useNavigate();
  const { token } = useParams();
  const ResetPassword = async (val: any) => {
    try {
      setIsLoading(true);
      let url = API.USER_RESET_PASSWORD;
      const obj = {
        token: token,
        password: val.password,
      };
      const passwordChange: any = await POST(url, obj);
      if (passwordChange.status) {
        notificationApi.success({message:`Password updated successfully.`});
        setTimeout(() => {
          navigation("/login");
        }, 1800);
      } else {
        notificationApi.error({message:passwordChange?.message});
      }
    } catch (err) {
      notificationApi.error({message:`Something Went wrong!`});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Screen-box">
      <br /> <br />
      <Container>
        <Row>
          <Col sm={4} xs={12}></Col>
          <Col sm={4} xs={12}>
            <h2 className="LoginScreen-txt1">Reset Your Password </h2>
            <br />
            <div className="LoginScreen-box1">
              {contextHolder}
              <Form onFinish={ResetPassword}>
                <div className="input-form-label">Enter New password</div>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "password is required", min: 8 },
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Enter password" size="large" />
                </Form.Item>
                <div className="input-form-label">Confirm password</div>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    { required: true, message: "Confirm password" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  dependencies={["password"]}
                  hasFeedback
                >
                  <Input.Password placeholder="Confirm password" size="large" />
                </Form.Item>
                <Row>
                  <Col sm={6} xs={12}></Col>
                  <Col sm={6} xs={12}>
                    <Button
                      loading={isLoading}
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <br />
          </Col>
          <Col sm={4} xs={12}></Col>
        </Row>
      </Container>
    </div>
  );
}
export default ResetPassword;
