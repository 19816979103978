import React, { useState } from "react";
import "../styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { clearCart } from "../../../redux/slices/cartSlice";
import { CgLogOut, CgProfile } from "react-icons/cg";
import { FaRegAddressBook, FaShoppingCart } from "react-icons/fa";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { clearStore } from "../../../redux/slices/storeSlice";
import EditProfilePhoto from "../screens/components/editProfilePhoto";
import { logoutChannel } from "../../../logout";

const Sidebar = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const iconSize = 20;
  const params = useParams();
  const User = useSelector((state: any) => state.User.user);
  const [modal3, setModal3] = useState(false);

  const routes = [
    {
      title: "Profile",
      icon: <CgProfile size={iconSize} />,
      path: "/profile/home",
      route: "home",
    },
    {
      title: "Orders",
      icon: <FaShoppingCart size={iconSize} />,
      path: "/profile/orders",
      route: "orders",
    },
    {
      title: "Favourites",
      icon: <MdOutlineFavoriteBorder size={iconSize} />,
      path: "/profile/favourites",
      route: "favourites",
    },
    {
      title: "Address",
      icon: <FaRegAddressBook size={iconSize} />,
      path: "/profile/address",
      route: "address",
    },
  ] as const;
  const handleeditcancel = () => {
    setModal3(false);
  };
  return (
    <div>
      <div className="userSidebar-storeBox">
        <div
          onClick={() => {
            setModal3(true);
          }}
        >
          <img
            src={User?.data?.image}
            className="userSidebar-logo"
            alt="userImage"
          />
        </div>
        <div style={{ marginLeft: 10, overflow: "hidden" }}>
          <div className="userSidebar-txt1 text-uppercase">
            {User?.data?.first_name ?? ""}
          </div>
          <div className="userSidebar-txt2">{User?.data?.username ?? ""}</div>
        </div>
      </div>
      {routes.map((item: any, key: number) => {
        return (
          <div
            key={key}
            className={` ${
              params["*"]?.includes(item.route)
                ? "profile-menu-selected"
                : "profile-menu"
            }`}
            onClick={() => navigation(item.path)}
          >
            {item.icon}
            <div className="profile-sidebar-txt1">{t(item.title)}</div>
          </div>
        );
      })}
      <div
        className={` ${"profile-menu"}`}
        onClick={() => {
          logoutChannel.postMessage("Logout");
          dispatch(logout(null));
          dispatch(clearCart(null));
          dispatch(clearStore(null));
          navigation("/");
        }}
      >
        <CgLogOut size={iconSize} />
        <div className="profile-sidebar-txt1" style={{color:"red"}}>{t("Logout")}</div>
      </div>
      <EditProfilePhoto open={modal3} close={handleeditcancel} />
    </div>
  );
};

export default Sidebar;
