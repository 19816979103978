import { Button, Pagination, Popconfirm, Table, notification } from "antd";
import { AiTwotoneDelete } from "react-icons/ai";
import { BiSolidEditAlt } from "react-icons/bi";
import API from "../../config/API";
import React from "react";
import { FcApprove } from "react-icons/fc";
import { PUT } from "../../utils/apiCalls";
function DataTable(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {contextHolder}

          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to Approve this transaction request?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => approveDriverRequest(_record)}
          >
            <FcApprove cursor="pointer" size={25} color={API.COLOR} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const approveDriverRequest = async (item: any) => {
    const url = API.APPROVE_DRIVER_REQUEST + item?.id;
    try {
      const response: any = await PUT(url, {});
      if (response?.status) {
        Notifications["success"]({
          message: "Payment request approved Successfully",
          description: "",
        });
        props?.getDriverRequest();
      } else {
        Notifications["error"]({
          message: response?.message,
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: `Something went wrong.`,
        description: "",
      });
    }
  };
  return (
    <div>
      <Table
        size="small"
        dataSource={props?.data?.length ? props.data : []}
        columns={columns}
        pagination={false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
