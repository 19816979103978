import React, { useEffect, useState } from "react";
import { Pagination, Popover, Table, notification, Modal } from "antd";
import API from "../../config/API";
import { AiOutlineEye } from "react-icons/ai";
import "./styles.scss";
import { GET } from "../../utils/apiCalls";
function DataTableEnquiry(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: () => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={showModal}
          >
            <AiOutlineEye cursor="pointer" size={25} color={API.COLOR} />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {contextHolder}

      <Table
        size="small"
        dataSource={props?.data ?? []}
        columns={columns}
        loading={props?.loading ?? false}
        rowKey={(data: any) => data?.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setSelectedRecord(record);
              showModal();
            },
          };
        }}
        pagination={false}
      />

      <Pagination
        current={props?.currentPage || 1}
        pageSize={props?.pageSize || 10}
        total={props?.meta?.itemCount || 0}
        defaultCurrent={1}
        responsive={true}
        defaultPageSize={props?.pageSize || 10}
        disabled={false}
        hideOnSinglePage={true}
        onChange={props?.handlePageChange}
        style={{ marginTop: "20px", textAlign: "center" }}
      />

      <Modal open={isModalOpen} onCancel={handleCancel} footer={null} centered>
        <div>
          <p className="dataTableEnquiry-Box1">Connect With Us Details</p>
          {selectedRecord && (
            <>
              <div className="dataTableEnquiry-Box2">
                <p className="dataTableEnquiry-Box3">
                  <div className="dataTableEnquiry-Txt1">Email</div>
                  <div className="dataTableEnquiry-Txt3">
                    :&nbsp; {selectedRecord.email}
                  </div>
                </p>
                <p className="dataTableEnquiry-Box3">
                  <div className="dataTableEnquiry-Txt2">Message</div>
                  <div className="dataTableEnquiry-Txt3">
                    :&nbsp; {selectedRecord.message}
                  </div>
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default DataTableEnquiry;
