import { useNavigate } from "react-router-dom";
import "./style.scss";
import React from "react";
import { FaStar } from "react-icons/fa6";
import { IoIosMore } from "react-icons/io";
function StoreItem(props: any) {
  const navigate = useNavigate();
  function formatDistance(distance:number) {
    if (distance < 0.1) {
        return "10 mtr";
    } else if (distance < 1) {
        return Math.round(distance * 1000) + " mtr";
    } else {
        return distance.toFixed(2) + " Kms";
    }
}
  return (
    <div
      className="StoreItem"
      onClick={() => navigate(`store/${props?.item?.slug}/main`)}
    >
      <div>
        <img src={props?.item?.logo_upload} className="StoreItem-img" />
      </div>
      <div className="StoreItem-box1">
        <div>
          <div className="StoreItem-txt1">{props?.item?.store_name}</div>
          <div className="StoreItem-txt2">
            {Array.isArray(props?.item?.business_types)
              ? props?.item?.business_types?.map((item: string) => `${item}, `)
              : ""}
          </div>
        </div>

        <div className="StoreItem-txt3">
          <FaStar color="#f5da42" /> &nbsp;
          {Number(props?.item?.averageRating)?.toFixed(1)} (
          {props?.item?.ratings}) <span className="text-success">{formatDistance(props?.item?.distance)}</span>
        </div>
      </div>
      <div>
        <div className="StoreItem-txt4">
          <IoIosMore />
        </div>
      </div>
    </div>
  );
}

export default StoreItem;
