import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../config/API";
import { GET } from "../../../utils/apiCalls";
import NoData from "../../../components/noData";
import Loading from "../../../components/loading";
import OrderDataTable from "./orderTable";

function Orders() {
  const [orderDetails, setOrderDetails] = useState<any>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const pageSize = 10;
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>({});

  const getOrderDetails = async (currentPage: number = page) => {
    setLoading(true);
    const url =
      API.ORDER_GET_USER +
      params?._id +
      `?order=DESC&page=${currentPage}&take=${pageSize}`;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setOrderDetails(response?.data);
        setMeta(response?.meta);
      } else {
      }
    } catch (err: any) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  const changePage = async (page: number) => {
    await getOrderDetails(page);
    setPage(page);
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    // <>
    //   {loading ? (
    //     <AdminLoading />
    //   ) : orderDetails.length ? (
    //     orderDetails.map((order: any, index: number) => (
    //       <div key={index}>
    //         <div className="order-block">
    //           <div className="order-block"></div>
    //           <Row className="mt-2">
    //             <Col md="4">
    //               <div className="">{"Order ID"}</div>
    //             </Col>
    //             <Col md="8">: {order.id}</Col>
    //           </Row>
    //           <Row className="mt-2">
    //             <Col md="4">
    //               <div className="">{"User ID"}</div>
    //             </Col>
    //             <Col md="8">: {order.userId}</Col>
    //           </Row>
    //           {/* zkczc */}
    //           {/* <Row className="mt-2">
    //             <Col md="4">
    //               <div className="">{"Total Price"}</div>
    //             </Col>
    //             <Col md="8">: {order.totalPrice}</Col>
    //           </Row> */}
    //           <Row className="mt-2">
    //             <Col md="4">
    //               <div className="">{"Address"}</div>
    //             </Col>
    //             <Col md="8">: {order.address}</Col>
    //           </Row>
    //           <Row className="mt-2">
    //             <Col md="4">
    //               <div className="">{"Status"}</div>
    //             </Col>
    //             <Col md="8">: {order.status}</Col>
    //           </Row>
    //         </div>
    //         {index !== orderDetails.length - 1 && (
    //           <hr className="order-divider" />
    //         )}
    //       </div>
    //     ))
    //   ) : (
    //     <NoData />
    //   )}
    // </>

    <div>
      {loading ? (
        <Loading />
      ) : orderDetails.length > 0 ? (
        <OrderDataTable
          data={orderDetails}
          changePage={changePage}
          meta={meta}
          page={page}
        />
      ) : (
        <NoData header="No Orders found"/>
      )}
    </div>
  );
}
export default Orders;
