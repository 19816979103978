import React, { useEffect, useState } from "react";
import API from "../../config/API";
import { useParams } from "react-router-dom";
import { GET } from "../../utils/apiCalls";
import Loading from "../../components/loading";
import NoData from "../../components/noData";
import moment from "moment";
import { message } from "antd";

function ViewOrderInvoice() {
  const params = useParams();
  const [invoice, setInvoice] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const getInvoice = async () => {
    const url = API.ORDER_INVOICE_GET_ONE + params.token;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setInvoice(response.data);
      } else {
        messageApi.error(response.message);
      }
    } catch (err) {
      messageApi.error(`Something went wrong`);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInvoice();
  }, []);
  return (
    <div className="Screen-box my-3">
      {contextHolder}
      {loading ? (
        <Loading />
      ) : invoice?.order_id ? (
        <div
          style={{
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
            transition: "0.3s",
            maxWidth: "600px",
            backgroundColor: "#fff",
            margin: "0 auto",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <br />
            <div
              style={{ color: "#262941", fontWeight: "bold", fontSize: "20px" }}
            >
              INVOICE
            </div>
          </div>

          <div style={{ padding: "20px" }}>
            <p style={{ fontSize: "16px" }}>Sold By: {API.NAME}</p>
            <p
              style={{
                fontSize: "13px",
                color: "rgb(29, 29, 29)",
                wordBreak: "break-all",
                textAlign: "start",
                margin: "0px",
                whiteSpace: "wrap",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Invoice Address:</span>{" "}
              {invoice?.addressDetails?.fullAddress ?? ""}
            </p>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <p
                style={{
                  border: "1px dotted black",
                  padding: "4px",
                  margin: "0px",
                  fontSize: "13px",
                }}
              >
                <span style={{ fontWeight: "bold" }}> Invoice Number:</span>
                ROCKANA{invoice?.order_id ?? ""}
              </p>
            </div>
            <hr />
            <table
              style={{
                width: "100%",
                tableLayout: "fixed",
                backgroundColor: "rgb(243, 243, 243)",
                padding: "10px",
              }}
            >
              <tr>
                <td>
                  <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                    Invoice Date:{" "}
                    {new Date(invoice?.createdAt).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      timeZone: "UTC",
                    }) ?? ""}
                  </div>
                  <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                    Due Date:{" "}
                    {new Date(invoice?.createdAt).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      timeZone: "UTC",
                    }) ?? ""}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      whiteSpace: "wrap",
                    }}
                  >
                    <p style={{ wordBreak: "break-all" }}>
                      Bill To:
                      {invoice.userDetails?.name ?? ""}
                      <br />
                      {invoice?.addressDetails.flat},<br />
                      {invoice?.addressDetails.street},<br />
                      {invoice?.addressDetails.city},<br />
                      {invoice?.addressDetails.state},<br />
                      {invoice?.addressDetails.pin_code},<br />
                      Phone No: {invoice?.addressDetails.code}
                      {invoice?.addressDetails.alt_phone}
                    </p>
                  </div>
                </td>
              </tr>
            </table>
            <br />
            <div style={{ fontSize: "13px" }}>
              Total Items: {invoice?.orderItems.length ?? 0}
            </div>
            <table
              style={{
                fontSize: "13px",
                width: "100%",
                tableLayout: "fixed",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "rgb(245, 245, 245)" }}>
                  <th>Product</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {invoice?.orderItems?.map((item: any, index: number) => (
                  <tr key={index} style={{ textAlign: "center" }}>
                    <td>{item?.name ?? "----"}</td>
                    <td>{item?.quantity ?? "----"}</td>
                    <td>{item?.price ?? "----"}</td>
                    <td>{item?.totalPrice ?? "----"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr />
            <table
              style={{
                fontSize: "13px",
                width: "100%",
                tableLayout: "fixed",
              }}
            ></table>
            <br />
            <div style={{ width: "100%", display: "flex" }}>
              <div style={{ width: "100%" }}></div>
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      textAlign: "right",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Total Items
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    :&nbsp;{invoice?.totalItems ?? 0}
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      textAlign: "right",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Total
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    :&nbsp;{invoice?.total ?? 0}
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      textAlign: "right",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Discount
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    :&nbsp;{invoice?.discount ?? 0}
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      textAlign: "right",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Delivery
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    :&nbsp;{invoice?.deliveryCharge ?? 0}
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      textAlign: "right",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Tax
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    :&nbsp;{invoice?.tax ?? 0}
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      textAlign: "right",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Grand Total
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    :&nbsp;{invoice?.grandTotal ?? 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
}

export default ViewOrderInvoice;
