import { createSlice } from "@reduxjs/toolkit";
const PosSlice = createSlice({
  name: "POS",
  initialState: {
    pos: [],
  },

  reducers: {
    storePos: (state, action) => {
      state.pos = action.payload;
    },
    clearPos: (state, action) => {
      state.pos = [];
    },
  },
});

export const { storePos, clearPos } = PosSlice.actions;
export default PosSlice.reducer;
