import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import ExpressIcon from "../../assets/images/EDbadge.png";
import EDfilter from "../../assets/images/EDfilter.png";
import PageHeader from "../../components/pageHeader";
import { useEffect } from "react";
import React from "react";
import API from "../../config/API";
function TermsandCond() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt m-2">
        <PageHeader text="Terms and Conditions" />
        <h5>Terms and Conditions</h5>
        <h5 className="mt-4">Introduction</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          Welcome to {API.NAME}, a service provided by {API.NAME} LLC. By using the {API.NAME} service, you are deemed to have accepted the terms and conditions listed below.
        </div>

        <h5 className="mt-4">Definitions</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li><strong>Service:</strong> The online platform for ordering construction materials.</li>
            <li><strong>User:</strong> The person or entity using the {API.NAME} platform.</li>
            <li><strong>Supplier:</strong> The crushers and other vendors providing construction materials.</li>
            <li><strong>Delivery:</strong> The transportation of ordered materials to the user's specified site.</li>
          </ul>
        </div>

        <h5 className="mt-4">Account Terms</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li>Users must create an account to place orders on the {API.NAME} platform.</li>
            <li>Users are responsible for maintaining the confidentiality of their account information.</li>
            <li>Users must immediately notify {API.NAME} of any unauthorized use of their account.</li>
          </ul>
        </div>

        <h5 className="mt-4">Order Processing</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li>Orders can be placed through the {API.NAME} website or mobile app.</li>
            <li>All orders are subject to availability and confirmation by the Supplier.</li>
            <li>Users will receive an order confirmation once the Supplier accepts the order.</li>
          </ul>
        </div>

        <h5 className="mt-4">Delivery Terms</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li>Delivery times are estimated and may vary due to factors beyond our control.</li>
            <li>{API.NAME} is not responsible for delays caused by the Supplier or external factors such as traffic or weather conditions.</li>
            <li>Users must ensure that the delivery site is accessible and safe for the delivery vehicle.</li>
          </ul>
        </div>

        <h5 className="mt-4">User Responsibilities</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          <ul>
            <li>Users must provide accurate information when placing orders.</li>
            <li>Users must comply with all applicable laws and regulations.</li>
            <li>Users must not use the {API.NAME} platform for any unlawful or fraudulent activities.</li>
          </ul>
        </div>

        <h5 className="mt-4">Limitation of Liability</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          {API.NAME} LLC will not be liable for any indirect, incidental, or consequential damages arising from the use of our services.
        </div>

        <h5 className="mt-4">Changes to Terms</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          {API.NAME} LLC reserves the right to modify these terms at any time. Users will be notified of any changes through the platform.
        </div>

        <h5 className="mt-4">Contact Us</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
          If you have any questions about these Terms and Conditions, please contact us at support@rockana.com.
        </div>
        
        <div className="mt-5">
          <SubscribeNewsletter />
        </div>
      </div>
    </div>
  );
}
export default TermsandCond;
