import { Bar as BarChart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Colors,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "../styles.scss";
import React from "react";
import moment from "moment";
function OverViewGraph(props:any) {
  const options = {
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stepSize: 5, // Remove this line
        max: 50,
        ticks: {
          stepSize: 5, // Define the step size here
        },
      },
    },
  };
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors
  );
  const month = Array.isArray(props?.orders)
    ? props?.orders?.map((item: any) =>
        moment(item?.orderDate).format("MMM Do YYYY")
      )
    : [];
  const dataset = Array.isArray(props?.orders)
    ? props?.orders?.map((item: any) => Number(item?.orderCount))
    : [];
  return (
    <div className="DashboardPurschaseGrph-Box1">
      <div className="DashboardPurschaseGrph-Tx1">Your orders for last 7 days</div>
      <BarChart
        className="DashboardPurschaseGrph-Box2"
        options={options}
        data={{
          labels: month,
          datasets: [
            {
              label: "order",
              data: dataset,
            },
          ],
        }}
      />
    </div>
  );
}

export default OverViewGraph;
