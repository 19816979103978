import React from "react";
import { Col, Row } from "react-bootstrap";
import { FcBullish, FcInTransit } from "react-icons/fc";
import { FcBearish } from "react-icons/fc";
import { MdTrolley } from "react-icons/md";
import API from "../../../config/API";
const productsOverView = (props:any) => {
  const cardData = [
    {
      title: "ORDERS",
      amount: props.totalOrders,
      icon: <FcInTransit size={50} />,
    },
    {
      title: "PRODUCTS",
      amount: props?.totalProducts,
      icon: <MdTrolley size={45} color={API.COLOR} />,
    },
    {
      title: "PENDING AMOUNT",
      amount: "0",
      icon: <FcBearish size={42} />,
    },
    {
      title: "TOTAL AMOUNT",
      amount: `${Number(props?.orderTotal)?.toFixed(2)??0}`,
      icon: <FcBullish size={42} />,
    },
  ];
  return (
    <Row>
      {cardData.map((item: any) => (
        <Col key={item} md={3}>
          <div className="productsOverView-Box2">
            <div>
              <div className="productsOverView-Txt1">{item.title}</div>
              <div style={{ height: 30 }} />
              <div className="productsOverView-Txt2">{item.amount}</div>
            </div>
            <div>{item.icon}</div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default productsOverView;
