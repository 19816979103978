import React, { useState, useEffect } from "react";
import { Table, Pagination } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "../styles.scss";
import API from "../../../config/API";

function DashBoardDataTable(props: any) {
  const [meta, setMeta] = useState<any>({});
  const pageSize = 10;
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();

  const changePage = async (page: number) => {
    // await getSellerDetails(page);
    setPage(page);
  };

  const viewDetailsPage = (record: any) => {
    navigate(`/auth/users/${record._id}`);
  };

  useEffect(() => {
    // getSellerDetails();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "View",
      key: "actions",
      width: 50,
      render: (_text: any, _record: any) => (
        <div
          style={{ display: "flex", justifyContent: "space-around" }}
          onClick={() => viewDetailsPage(_record)}
        >
          <AiOutlineEye cursor="pointer" size={25} color={API.COLOR} />
        </div>
      ),
    },
  ];

  return (
    <div className="dashBoardDataTable-Box1">
      <div className="dashBoardDataTable-Box2">
        <div className="dashboardText">New Users on Today</div>
      </div>
      <Table
        size="small"
        dataSource={Array.isArray(props?.data) ? props?.data : []}
        columns={columns}
        style={{ width: "100%" }}
        scroll={{ x: true }}
        pagination={false}
        loading={props?.loading ?? false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page || 1}
          pageSize={pageSize || 10}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
    </div>
  );
}

export default DashBoardDataTable;
