import { AutoComplete, Form, Input, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import useDebounce from "../../../shared/hook/useDebounce";
import { AUTO_COMPLETE } from "../../../utils/apiCalls";
import API from "../../../config/API";
import {
  clearLocation,
  storeLocation,
} from "../../../redux/slices/locationSlice";
import { useDispatch } from "react-redux";
import { CiSearch } from "react-icons/ci";
import { LoadingOutlined } from "@ant-design/icons";

function AutoCompleteLocationInput(props: any) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<any[]>([]);
  const [searchParam, setSearchParam] = useState("");
  const searchLocation = useDebounce(searchParam, 300);
  const [notificationApi, contextHolder2] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const getAutoComplete = (value: string) => {
    setSearchParam(value);
  };
  const getLocation = (option: { value: string; key: string }) => {
    try {
      const locationObj: any = {};
      locationObj.Place_id = option.key;
      const url =
        API.GET_LOCATION + `?place_id=${option.key}&key=${API.GGL_TOKEN}`;
      fetch(url, {
        method: "get",
        headers: {
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          return response.json();
        })
        .catch((err) => {
          notificationApi.error({ message: `Failed to get your location` });
          setIsLoading(false);
          return;
        })
        .then(async (response) => {
          if (response?.status == "OK") {
            locationObj.latitude = response.results[0]?.geometry?.location?.lat;
            locationObj.longitude =
              response.results[0]?.geometry?.location?.lng;

            response.results?.map((item: any) => {
              let addressArray: string[] = [];
              let address: string = "";
              item?.address_components?.map((addressItem: any) => {
                if (!addressArray.includes(addressItem.long_name)) {
                  address = address + " " + addressItem.long_name;
                  addressArray.push(addressItem.long_name);
                }
                if (addressItem.types?.includes("country")) {
                  locationObj.country = addressItem.long_name;
                }
                if (addressItem.types?.includes("postal_code")) {
                  locationObj.postal_code = addressItem.long_name;
                }
                if (
                  addressItem.types?.includes("administrative_area_level_1")
                ) {
                  locationObj.state = addressItem.long_name;
                }
                if (
                  addressItem.types?.includes("administrative_area_level_3")
                ) {
                  locationObj.district = addressItem.long_name;
                }
                if (
                  addressItem.types?.includes("administrative_area_level_4")
                ) {
                  locationObj.taluk = addressItem.long_name;
                }
                if (addressItem.types?.includes("plus_code")) {
                  locationObj.plus_code = addressItem.long_name;
                }
                if (addressItem.types?.includes("street_address")) {
                  locationObj.street_address = addressItem.long_name;
                }
                if (addressItem.types?.includes("route")) {
                  locationObj.route = addressItem.long_name;
                }
              });
              locationObj.full_address = address;
              if (item.types?.includes("country")) {
                locationObj.country = item.formatted_address;
              }
              if (item.types?.includes("postal_code")) {
                locationObj.postal_code = item.formatted_address;
              }
              if (item.types?.includes("administrative_area_level_1")) {
                locationObj.state = item.formatted_address;
              }
              if (item.types?.includes("administrative_area_level_3")) {
                locationObj.district = item.formatted_address;
              }
              if (item.types?.includes("administrative_area_level_4")) {
                locationObj.taluk = item.formatted_address;
              }
              if (item.types?.includes("plus_code")) {
                locationObj.plus_code = item.formatted_address;
              }
              if (item.types?.includes("street_address")) {
                locationObj.street_address = item.formatted_address;
              }
              if (item.types?.includes("route")) {
                locationObj.route = item.formatted_address;
              }
            });

            dispatch(storeLocation(locationObj));
            props?.onCancel();
            setIsLoading(false);
            notificationApi.info({
              message: `Your location has been set to ${option.value}`,
            });
          } else {
            notificationApi.error({
              message: `Request failed ,reason:${response.status}`,
            });
            dispatch(clearLocation(null));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          notificationApi.error({ message: `Failed to get Location.` });
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getSuggetions = (result: any) => {
    const array: any[] = [];
    result?.predictions?.forEach((item: any) => {
      array.push({
        value: item?.structured_formatting?.main_text,
        key: item?.place_id,
      });
    });
    setOptions(array);
  };

  const locationAutoComplete = async (value: string) => {
    try {
      const response: any = await AUTO_COMPLETE(value);
      if (response?.status == "OK") {
        getSuggetions(response);
        setIsLoading(false);
      }
    } catch (error) {
      notificationApi.error({ message: `Failed to get Location details` });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    locationAutoComplete(searchLocation);
  }, [searchLocation]);

  return (
    <div>
      <Form.Item
        name="location"
        rules={[
          {required: true,
            message: "Please Enter Location"}]}
      >
        <AutoComplete
          options={options}
          value={searchParam}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={(value, option) => getLocation(option)}
          onChange={getAutoComplete}
        >
          <Input
            size="large"
            placeholder="Search for area or street name"
            prefix={
              isLoading ? (
                <Spin indicator={<LoadingOutlined spin />}/>
              ) : (
                <CiSearch />
              )
            }
          />
        </AutoComplete>
      </Form.Item>
    </div>
  );
}

export default AutoCompleteLocationInput;
