import React from "react";
import API from "../../config/API";
import { Button, notification, Pagination, Popconfirm, Table } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import { DELETE } from "../../utils/apiCalls";
import { useNavigate } from "react-router-dom";

function DataTable(props: any) {
  const navigate = useNavigate();
  const [Notifications, contextHolder] = notification.useNotification();

  const columns = [
    {
      title: "Complainant",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Respondent",
      dataIndex: "against",
      key: "against",
    },
    {
      title: "Complaint",
      dataIndex: "complaint",
      key: "complaint",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (text: any, record: any) => (
        <div
          style={{ display: "flex", justifyContent: "space-around" }}
          onClick={() => navigate(`details/${record?.id}`)}
        >
          <AiOutlineEye cursor="pointer" size={25} color={API.COLOR} />
        </div>
      ),
    },
  ];
  return (
    <div>
      {contextHolder}
      <Table
        size="small"
        dataSource={props.data}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {},
          };
        }}
        pagination={false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 10}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current, size);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
