import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ImagePicker from "../components/ImagePicker";
import { Button, Alert, notification, Card } from "antd";
import { IoAddCircleOutline } from "react-icons/io5";
import moment from "moment";

import productImg from "../../../assets/images/defaultProductImg.jpg"

function Images(props: any) {
  const [loading, setLoading] = useState(false);
  const [coverImg, setCoverImg] = useState<any>(props?.coverImg);
  const [Notifications, contextHolder] = notification.useNotification();
  const [data, setData] = useState<any>(
    props?.data.length
      ? props?.data
      : [
          {
            id: moment(new Date()).unix(),
            type: "img",
            file: "",
            url: "",
          },
        ]
  );

  const submit = async () => {
    try {
      if (!coverImg.file) {
        Notifications["error"]({
          message: `Please Select Cover Image`,
        });
        return;
      }
      props?.onChange(data);
      props?.setCoverImg(coverImg);
    } catch (err) {
      console.log("err", err);
    }
  };

  const CreateNewImage = async () => {
    try {
      setLoading(true);
      let arr = data;
      let obj = {
        id: moment(new Date()).unix(),
        type: "img",
        file: "",
        url: "",
        s3_url: "",
      };
      arr.push(obj);
      setData(arr);
      setTimeout(() => {
        setLoading(false);
      }, 5);
    } catch (err) {
      console.log("CreateNewImage ,err", err);
    }
  };

  const removeNewImage = async (index: number) => {
    try {
      setLoading(true);
      let arr = data;
      arr.splice(index, 1);
      setData(arr);
      setTimeout(() => {
        setLoading(false);
      }, 5);
    } catch (err) {
      console.log("CreateNewImage ,err", err);
    }
  };

  const updateImage = (value: any, index: number) => {
    try {
      setLoading(true);
      let arr = data;
      const item: any = arr[index];
      item["file"] = value.file;
      item["url"] = value.url;
      arr[index] = item;
      setData(arr);
      setTimeout(() => {
        setLoading(false);
      }, 5);
    } catch (err) {
      console.log("updateImage ,err", err);
    }
  };

  const handleSetDefaultImage = async () => {
    try {
      const response = await fetch(productImg);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const file = new File([blob], `${moment(new Date()).unix()}N.jpg`, {
        type: "image/jpeg",
      });
      const url = URL.createObjectURL(file);
      const defaultImg = {
        file: file,
        url: url,
      };
      setCoverImg(defaultImg);
      props?.setCoverImg(defaultImg);
    } catch (err) {
      console.log("err = = = ", err);
    }
  };

  useEffect(() => {
    console.log("Cover Image State:", coverImg);
  }, [coverImg]);

  return (
    <div>
      {contextHolder}
      <div style={{ minHeight: "64vh" }}>
        <Alert
          message="Follow below guid to reduce quality check failure"
          description={
            <ul>
              <li>
                Products must fill at least 85% of the image. Images must show
                only the product that is for sale, with few or no props
              </li>
              <li>
                Images may only contain text that is a part of the product. •
                Main images must have a pure white background, must be a photo
                (not a drawing), and must not contain excluded accessories.
              </li>
              <li>
                JPEG is the preferred image format, but you also may use TIFF
                and GIF files.
              </li>
            </ul>
          }
          type="warning"
          closable
        />
        <br />
        <Row>
          <Col className="col-12">
            <h5>Product Cover Image</h5>
          </Col>
          <Col sm="3" className="">
            <ImagePicker
              size={"lagre"}
              fileURL={coverImg?.url?.url ?? coverImg?.url ?? ""}
              remove={() => {
                setCoverImg({});
              }}
              onChange={(value: any) => {
                setCoverImg(value);
              }}
            />
          </Col>
          <Col sm="3" className="">
            <Card
              style={{
                position: "relative",
                height: "200px",
              }}
              cover={
                <img
                  alt="default"
                  src={productImg}
                  // src={"https://bairuha-bucket.s3.ap-south-1.amazonaws.com/rockana/assets/defaultProductImage.jpg"}
                  style={{ width: "100%", height: "100%" }}
                />
              }
            >
              <Button
                type="primary"
                className="position-absolute bottom-0 start-50 translate-middle-x mb-2"
                style={{
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                onClick={handleSetDefaultImage}
              >
                Choose this image
              </Button>
            </Card>
          </Col>
          <Col sm="6"></Col>
          <hr />
          {data?.map((item: any, index: number) => {
            return (
              <Col sm={3}>
                <ImagePicker
                  size={"lagre"}
                  fileURL={item?.url?.Location ?? item?.url}
                  remove={() => removeNewImage(index)}
                  onChange={(value: any) => {
                    updateImage(value, index);
                  }}
                />
              </Col>
            );
          })}
          <Col sm={3}>
            <div
              className="AddProducts-newImagBox"
              onClick={() => CreateNewImage()}
            >
              <IoAddCircleOutline size={40} />
              <div>Add New</div>
            </div>
          </Col>
        </Row>
      </div>
      <br />
      <Row>
        <Col sm={6}></Col>
        <Col sm={2}>
          <Button size="large" block onClick={() => props?.onBack()}>
            Back
          </Button>
        </Col>
        <Col sm={4}>
          <Button
            size="large"
            block
            type="primary"
            htmlType="submit"
            onClick={() => submit()}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default Images;
