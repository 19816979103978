import { Button, Form, Input, notification } from "antd";
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import API from "../../../../config/API";
import { PUT } from "../../../../utils/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../../redux/slices/userSlice";

function EditEmailChange(props: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();

  const updateEmail = async () => {
    try {
      const values = await form.validateFields();
      const { email } = values;
      const obj = {
        email,
      };
      setLoading(true);
      const url = API.USER_EMAIL_UPDATE;
      const Response: any = await PUT(url, obj);
      if (Response.status) {
        notificationApi.success({ message: "Email Updated Successfully" });
        dispatch(update(Response?.data));
        props?.emailClose();
      } else {
        notificationApi.error({ message: Response.message });
      }
    } catch (error) {
      notificationApi.error({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      {contextHolder}
      <Form form={form}>
        <Row>
          <Col md="6">
            <Form.Item name="email" initialValue={props?.email}>
              <Input size="large" placeholder="Enter Your Email" />
            </Form.Item>
          </Col>
          <Col md="6">
            <Button
              loading={loading}
              type="primary"
              size="large"
              onClick={updateEmail}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EditEmailChange;
