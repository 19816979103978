import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import API from "../../config/API";

const { Panel } = Collapse;

function Faq() {
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const navigate = useNavigate();

  const toggleTerms = (key: string | string[]) => {
    if (Array.isArray(key)) {
      setActiveKey(key);
    } else {
      setActiveKey(activeKey.includes(key) ? [] : [key]);
    }
  };

  const faqData = [
    {
      question: `What is ${API.NAME}?`,
      answer: [
        <>
          <li key={0}>
            <p>
            {API.NAME} is an online platform that allows users to order construction materials like sand, metals, and more from crushers. The items can be delivered by trucks directly to the construction site.
            </p>
          </li>
        </>,
      ],
    },
    {
      question: "How do I place an order?",
      answer: [
        <>
          <li key={0}>
            <p>
              To place an order, you need to create an account on our platform. Once your account is set up, you can browse through the available materials, add them to your cart, and proceed to checkout.
            </p>
          </li>
          <li key={1}>
            <p>
              You can also place an order using our mobile app, available for both Android and iOS devices.
            </p>
          </li>
        </>,
      ],
    },
    {
      question: "What payment methods do you accept?",
      answer: [
        "We accept various payment methods, including credit/debit cards, net banking, and popular digital wallets.",
      ],
    },
    {
      question: "Can I track my order?",
      answer: [
        <>
          <li key={0}>
            <p>
              Yes, once your order is dispatched, you will receive a tracking number. You can use this tracking number to monitor the delivery status of your order through our platform or mobile app.
            </p>
          </li>
        </>,
      ],
    },
    {
      question: "What is your cancellation policy?",
      answer: [
        <>
          <li key={0}>
            <p>
              Orders can be canceled before they are dispatched. To cancel an order, go to your order history, select the order you want to cancel, and click the cancel button.
            </p>
          </li>
          <li key={1}>
            <p>
              If the order has already been dispatched, it cannot be canceled. However, you may be eligible for a refund if the materials are defective or not as described.
            </p>
          </li>
        </>,
      ],
    },
    {
      question: "How can I contact customer support?",
      answer: [
        "You can contact our customer support team via email at support@rockana.com or call us at our customer support hotline. Our support team is available from 9 AM to 6 PM, Monday to Friday.",
      ],
    },
    {
      question: "What are your delivery charges?",
      answer: [
        <>
          <li key={0}>
            <p>
              Delivery charges vary depending on the distance from the supplier to the delivery location and the type of materials ordered. The exact delivery charge will be displayed during the checkout process.
            </p>
          </li>
        </>,
      ],
    },
    {
      question: "Do you offer bulk order discounts?",
      answer: [
        <>
          <li key={0}>
            <p>
              Yes, we offer discounts on bulk orders. Please contact our sales team at sales@rockana.com for more information and to get a customized quote based on your requirements.
            </p>
          </li>
        </>,
      ],
    },
    {
      question: "What should I do if I receive damaged or incorrect materials?",
      answer: [
        <>
          <li key={0}>
            <p>
              If you receive damaged or incorrect materials, please contact our customer support team immediately at support@rockana.com. Provide your order details and a description of the issue. We will investigate the matter and arrange for a replacement or refund as necessary.
            </p>
          </li>
        </>,
      ],
    },
    {
      question: "Can I change my delivery address after placing an order?",
      answer: [
        <>
          <li key={0}>
            <p>
              You can change your delivery address before the order is dispatched. To update your delivery address, please contact our customer support team with your order details and the new delivery address.
            </p>
          </li>
          <li key={1}>
            <p>
              If the order has already been dispatched, changing the delivery address may not be possible.
            </p>
          </li>
        </>,
      ],
    },
    {
      question: "Is there a minimum order quantity?",
      answer: [
        <>
          <li key={0}>
            <p>
              Yes, there is a minimum order quantity for certain materials. The minimum order quantity will be specified on the product page. If you have any specific requirements, please contact our sales team for assistance.
            </p>
          </li>
        </>,
      ],
    },
  ];

  return (
    <div className="Screen-box">
      <Row className="mx-2 my-4 ftscreen-fnt ">
        <div className="d-flex align-items-center pb-3 ml-3">
          <IoArrowBackSharp
            size={25}
            color="dark"
            onClick={() => navigate(-1)}
            cursor="pointer"
          />
          <h4 className="ps-3 pt-2">Frequently asked Questions</h4>
        </div>

        <Col md={12}>
          <Collapse
            accordion
            activeKey={activeKey}
            onChange={(key) => toggleTerms(key)}
          >
            {faqData.map((faq, index) => (
              <Panel
                key={index.toString()}
                showArrow={true}
                header={
                  <p
                    className={`fs-6 ${
                      activeKey[0] === index.toString() ? "fw-bold" : ""
                    }`}
                    onClick={() => toggleTerms(index.toString())}
                  >
                    {faq.question}
                  </p>
                }
              >
                <ul>
                  {faq.answer.map((item, i) => (
                    <li key={`faq_${i}`}>
                      {Array.isArray(item)
                        ? item.map((subItem, subIndex) => (
                            <React.Fragment key={`faq_${i}_sub_${subIndex}`}>
                              {subItem}
                            </React.Fragment>
                          ))
                        : item}
                    </li>
                  ))}
                </ul>
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}

export default Faq;
