import { Button, Form, Input, Modal, notification, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import PrefixSelector from "../prefixSelector";
import { POST, PUT } from "../../utils/apiCalls";
import API from "../../config/API";
import { useSelector } from "react-redux";

const ComplaintFormModal = (props: any) => {
  const update = props?.update
  const data = props?.data;
  const user = useSelector((state: any) => state.User.user);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const onSubmit = async (val: any) => {
    try {
      const obj = {
        orderId: Number(props?.orderId),
        role: user?.data?.role,
        against: val?.against,
        complaint: val?.complaint,
        email: val?.email,
        countryCode: val?.code,
        phone: val?.phone,
      };
      let response:any
      if(update){
         response = await PUT(`${API.GET_COMPLAINT_BY_ID}${data?.id}`,obj)
      }
      else{
        response = await POST(API.REGISTER_COMPLAINT, obj);
      }
      
      if (response?.status) {
        props?.showMessage(response?.status, response?.message);
        props?.closeModal();
        props?.refreshList();
      } else {
        props?.showMessage(response?.status, response?.message);
      }
    } catch (error: any) {
      props?.showMessage(false, error.message);
    }
  };
  return (
    <Modal
      title="Complaint Form"
      open={props?.openModal}
      onCancel={props?.closeModal}
      footer={null}
    >
      <Form
        initialValues={{
          code: data?.countryCode ? data?.countryCode : "+91",
          against: data?.against ? data?.against : "",
          complaint : data?.complaint ? data?.complaint : "",
          email : data?.email ? data?.email : "",
          phone : data?.phone ? data?.phone : ""
        }}
        onFinish={(val) => onSubmit(val)}
      >
        <Form.Item
          name="against"
          label="Against"
          rules={[
            {
              required: true,
              message: "Please select against whom the complaint is",
            },
          ]}
        >
          <Select
            options={props?.options}
          ></Select>
        </Form.Item>
        <Form.Item
          name="complaint"
          label="Complaint"
          rules={[{ required: true, message: "Please enter the complaint" }]}
        >
          <TextArea rows={4} placeholder="Complaint" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please enter a contact email" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input placeholder="email" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            {
              required: true,
              message: "Please input your phone number",
            },
            {
              max: 16,
              message: "Phone Number is Invalid",
            },
            {
              min: 4,
              message: "Phone Number is Invalid",
            }
          ]}
        >
          <Input
            addonBefore={<PrefixSelector />}
            style={{ width: "100%" }}
            placeholder="Enter Phone Number"
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            onKeyPress={(e) => {
              const isNumber = /^[0-9]*$/;
              if (!isNumber.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(ph) => setPhoneNumber(ph?.target?.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "20%", float: "right" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ComplaintFormModal;
