import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBeUQEDwg8W-6C-WiTVZMD9Cb7y742YXwA",
  authDomain: "rockana-c8687.firebaseapp.com",
  projectId: "rockana-c8687",
  storageBucket: "rockana-c8687.appspot.com",
  messagingSenderId: "10853938497",
  appId: "1:10853938497:web:7ed0d924bcd74e22019dc0",
  measurementId: "G-JPEBQRY78D"
};

const app = initializeApp(firebaseConfig);
const Auth = getAuth(app);
const GoogleProvide = new GoogleAuthProvider();
export { Auth, GoogleProvide };
