import PageHeader from "../components/PageHeader";
import { useEffect, useState } from "react";
import AdminLoading from "../components/AdminLoading";
import API from "../../config/API";
import DataTable from "./DataTable";
import { Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { GET } from "../../utils/apiCalls";
import Search from "antd/es/input/Search";
import { GrPowerReset } from "react-icons/gr";

function Products() {
  const navigate = useNavigate();
  type ModalState = "add" | "update";
  const [type, setType] = useState<ModalState>("add");
  const [page, setPage] = useState(1);
  const pageSize = 12;
  const User = useSelector((state: any) => state.User.user);
  const [products, setProducts] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [searching, setSearching] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [Notifications, contextHolder] = notification.useNotification();

  const fetchProducts = async (currentP: number = 1, search: string = "") => {
    setIsLoading(true);
    const url =
      API.PRODUCTS_BYSTORE +
      `?order=ASC&page=${currentP}&take=${pageSize}&name=${search}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setProducts(response?.data);
        setMeta(response?.meta);
      } else {
        Notifications["error"]({
          message: response.message ?? "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = async (page: number, take: number) => {
    await fetchProducts(page, search);
    setPage(page);
  };
  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
      setReset(true);
      setSearching(true);
      await fetchProducts(1, value);
      setPage(1);
      setSearching(false);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div>
      {contextHolder}
      <PageHeader
        title={`Products ${!isLoading ? `(${meta?.itemCount ?? "0"})` : ""}`}
      >
        <Button onClick={() => navigate("/auth/add-products")} type="primary">
          Add Product
        </Button>
        <Search
          placeholder="Search Product"
          allowClear
          enterButton="Search"
          size="middle"
          onSearch={onSearch}
          loading={searching}
        />
        {reset ? (
          <Button
            type="primary"
            icon={<GrPowerReset />}
            size={"middle"}
            onClick={() => {
              fetchProducts();
              setReset(false);
              setPage(1);
              setSearch("");
            }}
          />
        ) : null}
      </PageHeader>
      {isLoading ? (
        <AdminLoading />
      ) : (
        <DataTable
          data={products}
          getProducts={fetchProducts}
          changeType={() => setType("update")}
          page={page}
          pageSize={pageSize}
          changePage={changePage}
          meta={meta}
        />
      )}
    </div>
  );
}
export default Products;
