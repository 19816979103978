import React, { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import DataTable from "./dataTable";
import { GET } from "../../utils/apiCalls";
import API from "../../config/API";
import { notification } from "antd";
import AdminLoading from "../components/AdminLoading";
import NoData from "../../components/noData";

function DriverRequest() {
  const [request, setRequest] = useState<any[]>([]);
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);
  const pagesize = 10;
  const getDriverRequest = async (pag: number = 1) => {
    const url =
      API.GET_DRIVER_REQUEST + `?page=${pag}&take=${pagesize}&order=DESC`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setRequest(response?.data);
        setMeta(response?.meta);
      } else {
        Notifications["error"]({
          message: response?.message,
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDriverRequest();
  }, []);
  const changePage = async (page: number) => {
    await getDriverRequest(page);
    setPage(page);
  };
  return (
    <>
      <PageHeader title={`Driver Payment Request`}></PageHeader>
      {loading ? (
        <AdminLoading />
      ) : request?.length ? (
        <DataTable
          data={request}
          getDriverRequest={getDriverRequest}
          page={page}
          pageSize={pagesize}
          changePage={changePage}
          meta={meta}
        />
      ) : (
        <NoData header="No Requests are found"/>
      )}
    </>
  );
}

export default DriverRequest;
