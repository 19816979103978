import React, { useRef } from "react";
import PageHeader from "../../components/PageHeader";
import { Button, Tabs, TabsProps } from "antd";
import DriverDetailsPage from "./tabs/detailsScreen";
import SettlementSummary from "./tabs/settlementSummary";
import SettlementHistory from "./tabs/settlementHistory";
import { useParams } from "react-router-dom";
import SettlementModal from "./components/settlementModal";
import useToggle from "../../../shared/hook/useToggle";
export type ResetType = {
  reset: () => void;
};
function DriverDetailsScreen() {
  const params = useParams();
  const resetRef = useRef<ResetType>(null);
  const resetRef2 = useRef<ResetType>(null);
  const [modal, toggleModal] = useToggle(false);
  const items: TabsProps["items"] = [
    {
      key: "info",
      label: "Driver Info",
      children: <DriverDetailsPage id={params?.id}  />,
    },
    {
      key: "summary",
      label: "Settlement Summary",
      children: <SettlementSummary id={params?.id} ref={resetRef2} />,
    },
    {
      key: "history",
      label: "Settlement History",
      children: <SettlementHistory id={params?.id} ref={resetRef}/>,
    },
  ];
  return (
    <>
      <PageHeader title="Driver Details">
        <Button type="primary" onClick={toggleModal.bind(true)}>
          Settle Payments
        </Button>
      </PageHeader>
      <Tabs defaultActiveKey={"info"} items={items} onChange={() => {}} />
      <SettlementModal
        openModal={modal}
        closeModal={toggleModal.bind(false)}
        refresh={() => {
          resetRef?.current?.reset();
          resetRef2?.current?.reset();
        }}
        driverId={params?.id}
      />
    </>
  );
}

export default DriverDetailsScreen;
