import React, { useEffect, useState } from "react";
import API from "../../config/API";
import { Button } from "antd";
import { GET } from "../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import NoData from "../../components/noData";
import AdminLoading from "../components/AdminLoading";
import Trucktable from "./dataTable";
import TruckFormModal from "./truckModal";

function Trucks() {
  const [truckData, setTruckData] = useState<{ data: any[]; meta: any }>({
    data: [],
    meta: {},
  });
  type ModalState = "add" | "update";
  const [type, setType] = useState<ModalState>("add");
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const pageSize = 10;
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const openModal = (item: any) => {
    setSelectedItem(item);
    toggleModal(true);
  };

  const toggleModal = (visible: boolean) => {
    setModalVisible(visible);
  };

  const receiveSelectedItem = (item: any) => {
    setSelectedItem(item);
  };

  const getTruckData = async (currentPage: number = page) => {
    try {
      let url = API.TRUCKS + `?order=DESC&page=${currentPage}&take=${pageSize}`;
      let data: any = await GET(url, null);
      setTruckData(data);
      setMeta(data?.meta || {});
    } catch (err) {
      console.log("error occurred while fetching data:", err);
    } finally {
      setLoading(false);
    }
  };
  const viewDetailsPage = (record: any) => {
    navigate(`/auth/trucks/${record.id}`);
  };

  const changePage = async (page: number) => {
    await getTruckData(page);
    setPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getTruckData();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <AdminLoading />;
    } else if (truckData?.data?.length > 0) {
      return (
        <Trucktable
          data={truckData.data}
          changePage={changePage}
          openModal={openModal}
          getSelectedItem={receiveSelectedItem}
          changeType={() => setType("update")}
          meta={meta}
          pageSize={pageSize}
          viewDetailsPage={viewDetailsPage}
          getTrucks={getTruckData}
          page={page}
        />
      );
    } else {
      return <NoData />;
    }
  };

  return (
    <>
      <PageHeader title="Trucks">
        <Button
          onClick={() => {
            toggleModal(true);
            setType("add");
          }}
          type="primary"
        >
          Add Truck +
        </Button>
      </PageHeader>
      <TruckFormModal
        open={modalVisible}
        data={selectedItem}
        modalClose={() => toggleModal(false)}
        getTrucks={getTruckData}
        type={type}
        page={1}
      />
      {renderContent()}
    </>
  );
}
export default Trucks;
