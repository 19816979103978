import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

function PaymentCard(props: any) {
  const Settings = useSelector((state: any) => state.Settings.Settings);
  return (
    <Card bordered={false}>
      <Meta
        // title={`PaymentType: ${props?.data?.orderPayment?.paymentType ?? ""}`}
        description={
          <div className="text-dark">
            <div>
              PaymentType: <span className="fw-bold">{props?.data?.orderPayment?.paymentType ?? ""}</span>
            </div>
            <div>
              Total ProductPrice:{Number(props?.data?.total)?.toFixed(2)}{" "}
              {Settings.currency}
            </div>
            <div>
              Tax: {Number(props?.data?.tax)?.toFixed(2)} {Settings.currency}
            </div>
            <div>
              Discount: {Number(props?.data?.discount)?.toFixed(2)}{" "}
              {Settings.currency}
            </div>
            <div>
              Delivery Charge: {Number(props?.data?.deliveryCharge)?.toFixed(2)}{" "}
              {Settings.currency}
            </div>
            <div>
              Grand Total: {Number(props?.data?.grandTotal)?.toFixed(2)}
              {Settings.currency}
            </div>
            <hr />
            <div className="fw-bold">
              Payment status:{" "}
              <span
                className={
                  props?.data?.orderPayment?.status == "pending"
                    ? "text-secondary"
                    : props?.data?.orderPayment?.status == "failed"
                    ? "text-danger"
                    : props?.data?.orderPayment?.status == "success"
                    ? "text-success"
                    : "text-info"
                }
              >
                {props?.data?.orderPayment?.status}
              </span>
            </div>
            <div className="fw-bold">
              {props?.data?.orderPayment?.ref ? "Amount Paid" : "Total Price"}:{" "}
              {Number(props?.data?.orderPayment?.amount)?.toFixed(2)}
              {Settings.currency}
            </div>
            {props?.data?.orderPayment?.ref ? (
              <div className=" fw-bold">
                Payment Reference: {props?.data?.orderPayment?.ref}
              </div>
            ) : null}

            <div>
              orderDate:{" "}
              {moment(props?.data?.orderPayment?.createdAt).format(
                "DD/MM/YYYY"
              )}
            </div>
            <div>
              Order ID:{" "}
              {props?.data?.order_id}
            </div>
          </div>
        }
      />
    </Card>
  );
}

export default PaymentCard;
