import { Table } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Settings from "../../settings/appSettings";
import API from "../../../config/API";
import { getOrderStatus } from "../../../shared/helpers/getOrderStatus";
function NewProductsOverView(props: any) {
  const navigate = useNavigate();
  const columns = [
    {
      title: "OrderId",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Customer Name",
      dataIndex: "userDetails",
      key: "userId",
      render: (item: any) => <span>{item?.name}</span>,
    },

    {
      title: "Status", //
      dataIndex: "status",
      key: "status",
      render: (item: string) => <span>{getOrderStatus(item)}</span>,
    },
    {
      title: "Total", //
      dataIndex: "total",
      key: "total",
      render: (item: any) => (
        <span>
          {item} {props?.currency}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <AiOutlineEye
            cursor="pointer"
            size={20}
            color={API.COLOR}
            onClick={() => {
              navigate(`/auth/Orders/${_record?.order_id}`);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <Table
      size="small"
      dataSource={props?.data?.length ? props.data : []}
      columns={columns}
    />
  );
}

export default NewProductsOverView;
